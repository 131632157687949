/*
documentation:
https://firebase.google.com/docs/reference/node/firebase.firestore.QuerySnapshot
*/

import firebase, {firebaseConfig} from './Firestore';
const {uniqueId} = require('../helpers/NameUtils');


class DataManager {

  constructor() {
    this.firebase = firebase;
    this.welcomed = false;
  }

  getFirebase = () => {
    return this.firebase;
  }

  setWelcomed = (val) => {
    this.welcomed = val;
  }

  getWelcomed = () => this.welcomed;

  uploadFile = async (file,filename) => {
    return new Promise((resolve, reject) => {
      var storageRef = this.firebase.storage().ref();
      var fileRef = storageRef.child(filename);
      
      fileRef.put(file).then(function(snapshot) {
        //console.log('Uploaded a blob or file!',snapshot);
        snapshot.ref.getDownloadURL().then(function(downloadURL) {
          //console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      });
    });
  }

  init = (app) => {
      this.app = app;

      firebase.initializeApp(firebaseConfig[this.app.getConfig().env]);
      firebase.analytics();

      this.db = firebase.firestore();
      this.allDataByModel = [];
      this.documentReads = 0;
      this.documentWrites = 0;
      this.models = {}
      
      firebase.auth().onAuthStateChanged((user) => {
          //console.log('INT Llama auth con',user);

          if (user) {
              this.user = user;
          } else {
              this.user = null;
              //Si no esta logueado, auto loguea con las credenciales por defecto
              //firebase.auth().signInWithEmailAndPassword("rentaequipos.dev@gmail.com","E77b9RY@u93=xs2e");
          }

          this.app.authChange(user);
      });
  }

  closeFirestoreSession(callback) {
    firebase.auth().signOut().then(() => {
      if(callback) callback();
    })
  }

  login(email,pass,callback) {
    //console.log('Trata de loguear con ',email,pass);
    firebase.auth().signInWithEmailAndPassword(email,pass).then((res) => {      
      //console.log('Login en firebase con',res);
      callback({user:res});
    }).catch(function(error) {
        //console.log('Error login firebase',error);            
        callback({error:true,errorMessage:error.message,errorCode:error.code});            
    });
  }

  getDataFromModelById(nombreColeccion,id) {

    if(!this.allDataByModel) return;

    var dataToReturn = this.allDataByModel[nombreColeccion];

    dataToReturn = dataToReturn.find((currentReg => {
      //console.log('Busca a ',currentReg);
      return currentReg.id===id
    }));    

    return dataToReturn?dataToReturn.data:{};
  }

  getAllDataByModel(nombreColeccion) {

    if(!this.allDataByModel) return;

    var dataToReturn = this.allDataByModel[nombreColeccion];

    //Algunos Modelos tienen una función para ordenar los datos antes de retornar
    if(this.models[nombreColeccion]) {
      if(this.models[nombreColeccion].default.sortData) {
        dataToReturn = this.models[nombreColeccion].default.sortData(dataToReturn);
      }
    }    

    return dataToReturn;
  }

  deleteUndefinedFields(info) {
    Object.keys(info).forEach((key) => {
      if(info[key]===undefined) {
        delete info[key];
      }
    })
  }

  async get(ref) {
    var getResult = await ref.get();
    this.documentReads+=getResult.size?getResult.size:1;
    //console.log('[GET] Regular GET',ref.path, "THIS_READ",getResult.size,"TOTAL_READS",this.documentReads);
    return getResult;
  }

  async set(ref,obj) {
    //return;
    this.documentWrites+=1;
    await ref.set(obj);
    //console.log('[SET] Regular SET',ref.path, "TOTAL_WRITES",this.documentWrites);
  }

  async saveData(model,info,callback) {
    var campos = model.campos;

    //console.log('Entra Save',info);
    var isNewItem = false;

    //Si entra acá es porque es un item nuevo
    if(info.ref===undefined) {
      //console.log('Es nuevo con',model.tipoDato);
      info.id = model.generateId(info.data,info.parentRef)
      isNewItem = true;

      //Si es un item principal
      if(info.parentRef===undefined) {
        info.ref = this.db.collection(model.tipoDato).doc(info.id);
      }
      //Si es una subcoleccion
      else {
        info.ref = info.parentRef.collection(model.tipoDato).doc(info.id);
        //console.log('Creo referencia en',info.ref.path);
      }
    }

    var dataDetalle = {}; //Los datos a guardar en la tabla de detalle
    var dataObject = {}; //Los datos a guardar en el nodo del item

    for (var index = 0; index < campos.length; index++) {
      const currentCampo = campos[index];

      //Si es un campo para la tabla de detalle
      if(currentCampo.isInDetalle===true) {
        dataDetalle[currentCampo.nombreDb] = info.data[currentCampo.nombreDb];
        if(dataDetalle[currentCampo.nombreDb]===undefined) {
          dataDetalle[currentCampo.nombreDb] = model.getDefaultItem()[currentCampo.nombreDb];
        }
      }
      //Si es un campo dependiente es una subcoleccion, que atiende la subcollecion currentCampo.data.key
      else if(currentCampo.tipo==='Dependant') {
        //console.log('Va a mirar el cambio dependiente',);
        var dataSubCollection = info.data[currentCampo.nombreDb];

        //console.log('La data de este campo es',dataSubCollection);

        for (var indexSubCollection = 0; indexSubCollection < dataSubCollection.length; indexSubCollection++) {
          const currentSubcollectionData = dataSubCollection[indexSubCollection];

          //Si hay que guardar cambios
          if(currentSubcollectionData.shouldBeSaved) {
            if(currentCampo.data.isSubcollection) {
              //console.log('Le setea parentRef a ',info.ref);
              currentSubcollectionData.parentRef = info.ref;
            }

            //
            await this.saveData(currentCampo.data.model,currentSubcollectionData)
          }
        }

        /*var allDataCollection = await data.ref.collection(currentCampo.data.key).get();

        var arrDocs = [];

        allDataCollection.forEach((currentDoc) => {
          arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
        })

        data.data[currentCampo.data.key] = arrDocs;*/
      }
      else {
        dataObject[currentCampo.nombreDb] = info.data[currentCampo.nombreDb];
        //console.log('NULL PARA',currentCampo.nombreDb,dataObject[currentCampo.nombreDb]);
        if(dataObject[currentCampo.nombreDb]===undefined) {
          dataObject[currentCampo.nombreDb] = model.getDefaultItem()[currentCampo.nombreDb];
        }
      }
    }


    if(model.tablaDetalle!==undefined) {
      dataDetalle[model.tablaDetalle.idDetalle] = info.id;
      var detalleRef = await this.get(this.db.collection(model.tablaDetalle.nombreTabla).where(model.tablaDetalle.idDetalle,"==",info.id))

      if(detalleRef.size>0) {
        detalleRef = detalleRef.docs[0].ref;
      }
      else {
        var uniqueIdDetalle = this.uniqueId();
        detalleRef = await this.db.collection('ClienteDetalle').doc(info.id+uniqueIdDetalle);
      }

      await detalleRef.set(dataDetalle);
    }

    if(isNewItem) {
      dataObject.created = firebase.firestore.FieldValue.serverTimestamp();
    }
    else {
      dataObject.updated = firebase.firestore.FieldValue.serverTimestamp();
    }

    //console.log('Pide hacer update/create',dataObject,'para',info.ref.path);
    //await info.ref.update(dataObject);
    await info.ref.set(dataObject,{merge: true});
    //await info.ref.set(dataObject);

    //console.log('Actualizar detalles con',dataDetalle,dataObject);
    if(callback) callback();
  }

  //Obtiene una subcoleccion perteneciente al modelName, donde el documento tenga id
  async getSubCollectionById(modelName,id,subCollection,fieldToValidate) {

    if(!id) return [];

    //Si es un objeto existente busca su colleccion
    var doc = await this.db.collection(modelName).doc(id);
    doc = doc.collection(subCollection);

    if(fieldToValidate!==undefined) {
      doc = doc.where(...fieldToValidate);
    }

    var docSnapshot = await this.get(doc);

    var arrDocs = [];

    docSnapshot.forEach((currentDoc) => {
      arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
    })

    return arrDocs;
  }

  async getSubCollectionFromRef(ref,subcollection) {
    //Si es un objeto existente busca su colleccion
    if(ref!==undefined) {

      var arrDocs = [];

      var docSnapshot = await this.get(ref.collection(subcollection));
      docSnapshot.forEach((currentDoc) => {
        arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
      })

      return arrDocs;
    }
    //Si no tiene ref entonces es uno nuevo
    else {
      return [];
    }
    //return this.allDataByModel[nombreColeccion];
  }

  async getSubCollection(dependantData,dbData,model) {
    //Si es un objeto existente busca su colleccion
    if(dbData.ref!==undefined) {

      var arrDocs = [];

      var docSnapshot = await this.get(dbData.ref.collection(dependantData.key));
      docSnapshot.forEach((currentDoc) => {
        arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
      })

      return arrDocs;
    }
    //Si no tiene ref entonces es uno nuevo
    else {
      return [];
    }
    //return this.allDataByModel[nombreColeccion];
  }

  removeAllSuscriptions() {
    if(this.bdSuscriptions)this.bdSuscriptions.forEach(unsubscribe => unsubscribe());
  }

  async readAndListenCollection(nombreColeccion,onChangeListener,noUpdateSnapshot) {

    //Pone el listener
    let doc = this.db.collection(nombreColeccion);
    //docSnapshot -> firebase.firestore.QuerySnapshot
    var unsubscribe = doc.onSnapshot(docSnapshot => {
      //.docs -> firebase. firestore. QueryDocumentSnapshot
      this.documentReads+=docSnapshot.size;
      //console.log('[GET UPDATE] ',docSnapshot.size, "TOTAL_READS",this.documentReads);

      var arrDocs = [];

      docSnapshot.forEach((currentDoc) => {
        arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
      })

      //console.log('Hubo un cambio en ',nombreColeccion,arrDocs);

      this.allDataByModel[nombreColeccion]=arrDocs;
      onChangeListener(nombreColeccion,arrDocs);

      if(!this.bdSuscriptions) this.bdSuscriptions = [];

      this.bdSuscriptions.push(unsubscribe);

      if(noUpdateSnapshot) {
        unsubscribe();
      }
    }, err => {
      //console.log(`Encountered error: ${err}`);
    });
  }

  /**
   * Obtiene un array con todos los QueryDocumentSnapshot de una colleccion
   * @param {string} nombreColeccion indica el nombre de la colleccion en la BD de la que se van a obtener todos los QueryDocumentSnapshot
   */
  async getAllSnapshotsByCollection(nombreColeccion) {
    return new Promise(async(resolve,reject) => {
      var data = await this.get(this.db.collection(nombreColeccion));
      resolve(data.docs);
    });
  }

  /**
   * Obtiene un array con todos los DocumentReference de una colleccion
   * @param {string} nombreColeccion indica el nombre de la colleccion en la BD de la que se van a obtener todos los DocumentReference
   */
  async getAllRefsByCollection(nombreColeccion) {
    return new Promise(async(resolve,reject) => {
      var data = await this.get(this.db.collection(nombreColeccion));
      var allRefs = [];

      if(data.size===0) resolve(allRefs);

      var processedItems = 0;

      data.forEach(async (result) => {
        allRefs.push(result.ref)
        processedItems++;
        if(data.size===processedItems) {
          resolve(allRefs)
        }
      })
    });
  }

  /**
   * Obtiene un array con todos los DocumentReference de una subcolleccion que pertenece a una coleccion
   * @param {string} nombreColeccion indica el nombre de la colleccion en la BD de la que se van a obtener todos los DocumentReference
   */
  async getAllRefsBySubCollection(nombreColeccion,nombreSubColeccion) {
    return new Promise(async(resolve,reject) => {
      var data = await this.get(this.db.collection(nombreColeccion));
      var allRefs = [];

      //console.log('Entra sub con',data.size);
      if(data.size===0) resolve(allRefs);

      var processedItems = 0;

      data.forEach(async (result) => {
        //console.log('Sigue',result);
        var data2 = await this.get(result.ref.collection(nombreSubColeccion));
        var allSubRefs = [];

        //console.log('Los sub son',data2,data2.size);

        var processedSubItems = 0;

        if(data2.size>0) {
          data2.forEach(async (result) => {
            allSubRefs.push(result.ref);
            processedSubItems++;
            if(data2.size===processedSubItems) {
              allRefs = allRefs.concat(allSubRefs);
              processedItems++;
              if(data.size===processedItems) {
                //console.log('Resuelve0',);
                resolve(allRefs)
              }
            }
          })
        }
        else {
          processedItems++;
          if(data.size===processedItems) {
            //console.log('Resuelve1',);
            resolve(allRefs)
          }
        }
      })
    });
  }

  //Por ejemplo los clientes no tienen toda su información en el documento para no tenerse que traer todos los datos de
  //todos los clientes solo para poderlos listar, entonces el modelo indica que los detalles adicionales estan en una tablaDetalle
  //llamada ClientesDetalle entonces cuando se necesitan todos los datos del cliente, esta función va y se trae los detalles adicionales
  //y los deja en el mismo objeto.
  async fillData(info,model,callback) {

    //console.log('Que haga fill a ',info,'model',model.tipoDato);
    //data = this.cloneData(data);

    if(model.tablaDetalle) {
      var detalleData = await this.get(this.db.collection(model.tablaDetalle.nombreTabla).where(model.tablaDetalle.idDetalle,"==",info.id));
      if(detalleData.size>0) {
        detalleData = detalleData.docs[0].data();
        detalleData[model.tablaDetalle.idDetalle] = undefined;
        delete detalleData[model.tablaDetalle.idDetalle];
        info.data = Object.assign({},info.data,detalleData)
        //console.log('Fusionar a ',info.data,detalleData);
        if(callback)callback(await this.addSubCollection(info,model));
      }
      else {
        console.error('SE PIDIO DETALLE PARA ',info,'PERO NO SE ENCUENTRA',model.tablaDetalle);
        if(callback)callback(await this.addSubCollection(info,model));
      }
    }
    else {
      if(callback)callback(await this.addSubCollection(info,model));
    }
  }

  async addSubCollection(data,model) {
    var campos = model.campos;

    for (var index = 0; index < campos.length; index++) {
      const currentCampo = campos[index];

      //Si es un campo dependiente es una subcoleccion, que atiende la subcollecion currentCampo.data.key
      if(currentCampo.tipo==='Dependant') {
        var allDataCollection = await this.get(data.ref.collection(currentCampo.data.key));

        var arrDocs = [];

        allDataCollection.forEach((currentDoc) => {
          arrDocs.push({id:currentDoc.id,data:currentDoc.data(),ref:currentDoc.ref});
        })

        data.data[currentCampo.data.key] = arrDocs;
      }
    }

    return data;
  }

  async removeDB_Collections(callbackMsg,arrCollections,strMsg) {
    return new Promise(async(resolve,reject) => {

      var items = [];

      for (var arrCollectionsIndex = 0; arrCollectionsIndex < arrCollections.length; arrCollectionsIndex++) {
        const element = arrCollections[arrCollectionsIndex];

        if(typeof element === 'string') {
          items = items.concat(await this.getAllRefsByCollection(element));
        }
        else {
          if(element.subCollection) {
            for (var currentSubCollectionIndex = 0; currentSubCollectionIndex < element.subCollection.length; currentSubCollectionIndex++) {
              const currentSubCollection = element.subCollection[currentSubCollectionIndex];
              var itemsSubCollection = await this.getAllRefsBySubCollection(element.dbCollection,currentSubCollection)
              //console.log('Los items de subcollection ',currentSubCollection,'son',itemsSubCollection);
              items = items.concat(itemsSubCollection);
            }
          }

          items = items.concat(await this.getAllRefsByCollection(element.dbCollection));
        }
      }

      var countItemsDeleted = 0;
      if(items.length===0) resolve(0);

      if(callbackMsg)callbackMsg("Borrando " + strMsg + " Anteriores (0/" + items.length + ")");

      items.forEach(async (result) => {
        await result.delete();

        countItemsDeleted++;
        if(callbackMsg)callbackMsg("Borrando " + strMsg + " Anteriores (" +countItemsDeleted + "/" + items.length + ")");

        if(items.length===countItemsDeleted) {
          resolve(countItemsDeleted)
        }
      })
    })

    /*items.forEach(async (result) => {
        await result.ref.delete();
      })
    })*/
  }

  resetData = async (callbackMsg) => {
    if(callbackMsg)callbackMsg("Eliminando datos");

    //Como acá empieza a actualizar todas las colecciones de BD entonces remueve las suscripciones
    //que hacen un get cada que la bd se modifica en real time, esto ya que generaria demasiados llamados
    this.removeAllSuscriptions();

    ///*
    await this.removeDB_Collections(callbackMsg,["Empresa","Factura"]);
    if(callbackMsg)callbackMsg("Proceso terminado (PARA USAR LOS DATOS DEBES RECARGAR)",true);
  }


  updateDB_All_FromRealData = async (callbackMsg,app) => {
    callbackMsg("Eliminando datos");

    //Como acá empieza a actualizar todas las colecciones de BD entonces remueve las suscripciones
    //que hacen un get cada que la bd se modifica en real time, esto ya que generaria demasiados llamados
    this.removeAllSuscriptions();

    ///*
    await this.removeDB_Collections(callbackMsg,[{dbCollection:'Cliente',subCollection:['obras']},'ClienteDetalle'],"Clientes");
    await this.removeDB_Collections(callbackMsg,[{dbCollection:'TipoProducto',subCollection:['productos']},'TipoProductoDetalle','ClienteInventarioComprometido','ClienteInventarioEntregado','ObraInventarioEntregado','Remision','Cardex','Devolucion','Configuracion'],"Items");

    this.set((await this.db.collection('Configuracion').doc('ConfActual')),{ultimaFactura:0});
    callbackMsg("Proceso terminado (PARA USAR LOS DATOS DEBES RECARGAR)",true);
  }

  //Como los objetos que tienen una referencia de firebase no pueden ser clonados entonces esta
  //funcion elimina todas las referencias
  deleteRefs(obj, hash = new WeakMap()) {
    // Do not try to clone primitives or functions
    if (Object(obj) !== obj || obj instanceof Function) return obj;
    if (hash.has(obj)) return hash.get(obj); // Cyclic reference
    try { // Try to run constructor (without arguments, as we don't know them)
        var result = new obj.constructor();
    } catch(e) { // Constructor failed, create object without running the constructor
        result = Object.create(Object.getPrototypeOf(obj));
    }
    // Optional: support for some standard constructors (extend as desired)
    if (obj instanceof Map)
        Array.from(obj, ([key, val]) => result.set(this.deleteRefs(key, hash),
                                                   this.deleteRefs(val, hash)) );
    else if (obj instanceof Set)
        Array.from(obj, (key) => result.add(this.deleteRefs(key, hash)) );
    // Register in hash
    hash.set(obj, result);
    // Clone and assign enumerable own properties recursively
    return Object.assign(result, ...Object.keys(obj).map (
        key => ({ [key]: key==="ref"?undefined:this.deleteRefs(obj[key], hash) }) ));
  }

  async removeDataFromFirestoreByRef(ref,nombreSubColeccion) {
    return new Promise(async(resolve,reject) => {

      var allRefs = [];

      if(nombreSubColeccion) {
        var itemsSubCollection = await this.get(ref.collection(nombreSubColeccion));
        var allSubRefs = [];

        if(itemsSubCollection.size>0) {
          itemsSubCollection.forEach(async (result) => {
            allSubRefs.push(result.ref);
          })

          allRefs = allRefs.concat(allSubRefs);
        }
      }

      allRefs.push(ref);

      //console.log('allRefs',allRefs);

      var countItemsDeleted = 0;

      //callbackMsg("Borrando " + strMsg + " Anteriores (0/" + items.length + ")");

      allRefs.forEach(async (result) => {
        await result.delete();

        countItemsDeleted++;
        //callbackMsg("Borrando " + strMsg + " Anteriores (" +countItemsDeleted + "/" + items.length + ")");

        if(allRefs.length===countItemsDeleted) {
          resolve(countItemsDeleted)
        }
      })
    })
  }
}

export default new DataManager();