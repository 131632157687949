const removeDiacritics = require('diacritics').remove;
const camelCase = require('camelcase');

module.exports.uniqueId = ()  => {
  return "_" + (Math.random().toString(36).substr(2, 9));
}

module.exports.getFileName = (nombre)  => {
  var nombreArchivo = nombre.replace(/¼/g,'Cuarto');
  nombreArchivo = nombreArchivo.replace(/½/g,'Medio');
  nombreArchivo = nombreArchivo.replace(/=/g,'');
  nombreArchivo = nombreArchivo.replace(/,/g,'');
  nombreArchivo = nombreArchivo.replace(/"/g,'');
  nombreArchivo = nombreArchivo.replace(/”/g,'');
  nombreArchivo = nombreArchivo.replace(/-/g,'');
  nombreArchivo = nombreArchivo.replace(/–/g,'');
  nombreArchivo = nombreArchivo.replace(/\//g,'');
  nombreArchivo = nombreArchivo.replace(/\\/g,'');
  nombreArchivo = nombreArchivo.replace(/\(/g,'');
  nombreArchivo = nombreArchivo.replace(/\)/g,'');
  nombreArchivo = camelCase(removeDiacritics(nombreArchivo),{pascalCase: true})
  nombreArchivo = nombreArchivo.replace(/\s/g,'');

  return nombreArchivo;
}

module.exports.toCurrency = (value)  => {
  var currencyFormat = new Intl.NumberFormat("en-US",
  { style: "currency", currency: "USD",
    minimumFractionDigits: 2 })

  return "USD" + currencyFormat.format(value);
}

module.exports.toPercentage = (value)  => {
  return (Math.floor(value*10000)/100) + " %";
}