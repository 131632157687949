import React, { Fragment } from 'react';
import {  BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
//import history from "./history";

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import ModalLoader from '../components/ModalLoader';
import DialogWindow from '../components/DialogWindow';

import dataManager from './DataManager';
import modelsLoader from '../data_loaders/ModelsLoader';

import { AppContext } from './AppContext';
import Fade from '../components/Animation/Fade';

import PdfRender from '../screens/PdfRender';
import Login from '../screens/Login';
import Home from '../screens/Home';
import Splash from '../screens/Splash';

const appTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#405c96',
      main: '#023368',
      dark: '#000c3d',
      contrastText: '#FFF',
    },
  },
  overrides: {
    palette: {
      primary: {        
        width: '0% !important',
      }
    },
  }
});


const styles = theme => ({
	root: {
    display: 'flex',
    height: '100%',
  },
});

class App extends React.Component {

  state = {
    inSplash:true,
    showModalLoader:false,
    showDialogWindow:false,
    DialogWindowData:{}
  }

  async componentDidMount() {   

    //console.log('Tengo de props',this.props);
    //console.log('MONTA VAR',process);

    this.dataEditorDatos = {
      Empresa: null,      
    }

    dataManager.init(this);
  }

  getConfig() {
    return {env: 'development'};
  }

  login = (email,password) => {
    this.showLoader(true);
    dataManager.login(email,password,
      (dataLogin) => {
        //console.log('Llega con ',dataLogin);
        if(dataLogin.error) {              
          var msg = dataLogin.errorCode;
          
          switch (dataLogin.errorCode) {
            case "auth/wrong-password":
              msg = "La contraseña es incorrecta";
              break;
  
            case "auth/user-not-found":
              msg = "No existe un usuario con ese correo electrónico";
              break;    
          
            default:
              msg = dataLogin.message;
              break;
          }
  
          this.showDialog({title:"Error al intentar ingresar",message:msg,buttons:[
            {label:"Continuar"}            
          ]});
          
          this.showLoader(false);    
        }  
  
      }
    )
  }

  closeSession = () => {
    //console.log('Llama a cerrar sesion',);
    dataManager.closeFirestoreSession();
  }
  
  authChange(user) {
    ////console.log('history',history);
    //console.log('Cambio en AUTH',user);
    if(user!==null) {
      
      //console.log('this',this);

      //this.props.history.push("/home");

      this.firstDataLoad = true;
      this.dbLoaded = 0;
      this.arrDBS = ['Empresa','Factura','Concepto']
      modelsLoader.init({noUpdateSnapshot:false,arrDBS:this.arrDBS});
      this.arrDBS.forEach(dbName => modelsLoader.setListener(dbName,this.onDbDataChange));

      //dataManager.resetData();
    }
    else {
      //history.
      this.props.history.push("/");
      this.setState({inSplash:false});
    }   
  }

  onDbDataChange = (dbName,data) => {

    //Esto es para quitar el cargador luego de la carga de los datos iniciales de la DB
    if(this.firstDataLoad) {
      this.dbLoaded++;
      if(this.dbLoaded===this.arrDBS.length) {
        this.showLoader(false);
        this.firstDataLoad = false;        
        
        if(this.props.history.location.pathname==="/") {
          this.props.history.push("/home");
        }
        
        this.setState({inSplash:false});
      }
    }

    //console.log('Cambio en App',dbName,this.state.currentScreen);
    
    this.dataEditorDatos[dbName] = data;

    if(this.state.currentScreen===dbName) {
      this.showLoader(false);
    }
    else {
      //this.forceUpdate();
    }
  }

  showDialog = (data) => {
    this.setState({DialogWindowData:data,showDialogWindow:true,showModalLoader:false});
  }

  showErrorMessageDialog = (msg) => {
    this.showDialog({title:"Error",message:msg,buttons:[
      {label:"Continue"}
    ]});
  }

  closeDialog = () => {
    this.setState({DialogWindowData:{},showDialogWindow:false});
  }

  showLoader = (val,loaderText='') => {
    this.setState({showModalLoader:val,loaderText})
  }

  changeLoaderText = (loaderText,removeLoader=false) => {
    if(removeLoader) {
      setTimeout(() => {
        this.setState({showModalLoader:false});
      }, 2000);
    }

    this.setState({loaderText})
  }

  appMenuBackOverride = (fnMenuBack) => {
    //console.log('SETEA MENU OVERRIDE',);
    this.fnMenuBack = fnMenuBack;
  }

  render() {

    const {currentSubScreen,inSplash} = this.state;

    return (
      <AppContext.Provider value={this}>
        <CssBaseline />
        <MuiThemeProvider theme={appTheme}>
          <ModalLoader showModalLoader={this.state.showModalLoader} text={this.state.loaderText}/>
          <DialogWindow showDialogWindow={this.state.showDialogWindow} data={this.state.DialogWindowData} closeModalWindow={this.closeDialog}/>

          {inSplash && <Splash />}
          {/*<Fade show={inSplash}>
            <Splash />
          </Fade>*/}
          {!inSplash && <Switch key="stay">
            <Route exact path='/pdf' component={() => <PdfRender data={
              {
  "billedCompany": {
    "data": {
      "ampliacionDireccion": "FL 33134",
      "casa": "Hola",
      "ciudad": "Miami",
      "created": {
        "seconds": 1585599004,
        "nanoseconds": 726000000
      },
      "direccion": "2222 Ponde de Leon Blvd, Suite 300",
      "documento": "18402524",
      "emiteFactura": true,
      "fecha": {
        "seconds": 1585598987,
        "nanoseconds": 566000000
      },
      "imagenMarcaAgua": "https://firebasestorage.googleapis.com/v0/b/dacotech-facturacion.appspot.com/o/Empresa1_zlnwudwih_imagenMarcaAgua_.jpg?alt=media&token=a1b639a7-b63d-48b4-b2fc-9cd22ec1fa89",
      "imagenSuperior": "https://firebasestorage.googleapis.com/v0/b/dacotech-facturacion.appspot.com/o/Empresa1_zlnwudwih_imagenSuperior_.jpg?alt=media&token=b8394f30-7dc6-43e6-9f88-593f5538845a",
      "item": "1",
      "nombre": "Dacotech Miami 3",
      "pais": "United States",
      "recibeFactura": true,
      "telefono": "3136597156",
      "tipoPersona": "Carlos",
      "updated": {
        "seconds": 1587569626,
        "nanoseconds": 983000000
      },
      "web": "www.dacotechcorp.com"
    },
    "id": "Empresa1_zlnwudwih"
  },
  "billingCompanyId": {
    "data": {
      "ampliacionDireccion": "",
      "ciudad": "",
      "created": {
        "seconds": 1585973480,
        "nanoseconds": 557000000
      },
      "direccion": "",
      "emiteFactura": true,
      "imagenMarcaAgua": "https://firebasestorage.googleapis.com/v0/b/dacotech-facturacion.appspot.com/o/DacotechColombia_r9saplavw_imagenMarcaAgua_.jpg?alt=media&token=09274927-37a7-416f-87c9-990ba8471783",
      "imagenSuperior": "https://firebasestorage.googleapis.com/v0/b/dacotech-facturacion.appspot.com/o/DacotechColombia_r9saplavw_imagenSuperior_.jpg?alt=media&token=3d4522c3-12f7-4c3d-971d-de0e629e4e89",
      "nombre": "Dacotech Colombia",
      "pais": "",
      "recibeFactura": true,
      "updated": {
        "seconds": 1585973670,
        "nanoseconds": 808000000
      },
      "web": ""
    }
  },
  "conceptos": [
    {
      "data": {
        "data": {
          "concepto": "GUI",
          "created": {
            "seconds": 1587272582,
            "nanoseconds": 577000000
          }
        },
        "id": "Gui_uwm6j6of7"
      },
      "id": 0,
      "porcentaje": "60"
    },
    {
      "cantidad": 1,
      "data": {
        "data": {
          "concepto": "ADS",
          "created": {
            "seconds": 1587272547,
            "nanoseconds": 419000000
          }
        },
        "id": "Ads_ap0q5o5gg"
      },
      "id": 1,
      "porcentaje": "40"
    }
  ],
  "consecutivo": "1",
  "created": {
    "seconds": 1586276186,
    "nanoseconds": 164000000
  },
  "fecha": {
    "seconds": 1586276177,
    "nanoseconds": 214000000
  },
  "fechaFinPeriodo": new Date(),
  "fechaIniPeriodo": new Date(),
  "updated": {
    "seconds": 1587358428,
    "nanoseconds": 719000000
  },
  "valor": "75676.73"
}
              }/>} />
            <Route exact path='/' component={Login} />
            <Route key="home" path='/home/:screen?/:id?/:objectId?' component={Home} />                   
          </Switch>}
        </MuiThemeProvider>
      </AppContext.Provider>    
    );
  }
}


export default withStyles(styles,{ withTheme: true })(App)