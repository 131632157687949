import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import InformesIcon from '@material-ui/icons/AssignmentTurnedIn';


import Divider from '@material-ui/core/Divider';


import clsx from 'clsx';

import CloseSessionIcon from '@material-ui/icons/ExitToApp';
import ApartmentIcon from '@material-ui/icons/Apartment';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const drawerWidth = 300;

const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
});

class MenuBar extends React.Component {

  state = {
    
  }

  getMenu = () => {
    var menu = [];
  
    menu.push({
      titulo: 'Facturación',
      icono: (<HomeIcon />),
      accion: 'GoTo',
      parametro: '',      
    })
    
    menu.push({
      titulo: 'Gestionar Empresas',
      icono: (<ApartmentIcon />),
      accion: 'GoTo',
      parametro: 'companies',      
    })

    menu.push({
      titulo: 'Gestionar Conceptos',
      icono: (<DragHandleIcon />),
      accion: 'GoTo',
      parametro: 'concepts',      
    })

    menu.push({
      titulo: 'Estadisticas',
      icono: (<InformesIcon />),
      accion: 'GoTo',
      parametro: 'estadisticas',      
    })
  
    /*menu.push({
      tipo: 'divisor'
    })*/
  
    menu.push({
      titulo: 'Cerrar sesión',
      icono: (<CloseSessionIcon />),
      accion: 'CloseSession',      
    })
  
    return menu;
  }
  
  handleDrawerOpen = () => {
    this.setState({drawerOpened:true});
  };
  
  handleDrawerClose = () => {
    this.setState({drawerOpened:false});
  };
  
  onMenuClick = (menu) => {
    if(this.props.onMenuClick(menu)) {
      this.handleDrawerClose();
    }
    /*if(menu.accion==="GoTo") {
      this.setState({currentScreen:menu.parametro,drawerOpened:false,currentTitulo:menu.titulo})
    }*/
  }

  render() {
    const { classes } = this.props;
    const { onCornerIconClick, showArrow, title} = this.props;

    var open = this.state.drawerOpened;

    return(
      <Fragment>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                if(!onCornerIconClick) { this.handleDrawerOpen() }
                else { 
                  if(onCornerIconClick()) {
                    this.handleDrawerClose();
                  }
                }
              }}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              {!showArrow?<MenuIcon />:<ArrowBackIcon />}
            </IconButton>
            <Typography variant="h6" noWrap>
              {title?title:''}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          onClose={this.handleDrawerClose}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {this.getMenu().map((menu, index) => {
              if(menu.tipo==="divisor") {
                return <Divider key={'div'+index}/>
              }

              return <ListItem button key={menu.titulo} onClick={() => {this.onMenuClick(menu)}}>
                <ListItemIcon>{menu.icono}</ListItemIcon>
                <ListItemText primary={menu.titulo} />
              </ListItem>
            })}
          </List>
        </Drawer>      
      </Fragment>
    )
    
  }
}

export default (withStyles(styles,{ withTheme: true })(MenuBar));
