module.exports.goToParent = (props)  => {
  var path = props.location.pathname;
  var pathArray = path.split("/");
  pathArray.pop();
  path = pathArray.join("/");
  props.history.push(path);
}

module.exports.getLastRoute = (props)  => {
  var path = props.location.pathname;
  var pathArray = path.split("/");
  return pathArray.pop();  
}
