import * as React from 'react';

import empresaModel from '../models/Empresa';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { toCurrency, toPercentage } from '../../helpers/NameUtils';

import dataManager from '../../app/DataManager';

const d3 = require("d3-scale-chromatic");

const clonedeep = require('lodash.clonedeep');


var getRandomColors = (size)  => {
  var arrColors = [];
  var colorGenerator = getRandomColor();

  for (var index = 0; index < size; index++) {
    arrColors.push(colorGenerator.next().value);    
  }

  return arrColors;
}

var getRandomColor = function*() {
  var colorList = [
    "#6DA2CA","#486FB0","#D23431","#ED6847","#F8A061",    
    "#F7CC07","#3C1BCD","#05E352","#EB3030","#2507FE","#B1DB28","#D62160","#3C0903","#54792F","#9B75F4","#B01F47","#F2022C","#1A465C"];

  for (var i = 0; i < colorList.length; i++) {
    yield colorList[i];
  }

  while(true) {
    //return d3.interpolateYlGnBu((Math.random()*0.5)+0.5);
    yield d3.interpolateTurbo(Math.random());
  }
}

var processAllDataClientes = async (data) => {
  if(!(data.empresa?.id!==undefined)) {
    return {error:"Seleccione la empresa para visualizar la estadística"}
  }
  else {
    var allFacturas = dataManager.getAllDataByModel("Factura");
    var allEmpresas = dataManager.getAllDataByModel("Empresa");


    var companyBill = allFacturas.filter((currentBill) => currentBill.data?.billingCompanyId === data.empresa.id || data.empresa.id==="Todos")

    var factsInfo = companyBill.reduce((prev,currentBill) => {
      if(!currentBill.data.billedCompany) return prev;

      var currentValue = parseFloat(currentBill.data.valor);
      var currentBilledId = currentBill.data.billedCompany.id;
      prev.totalBilled+=currentValue;
      
      if(!prev.billedData[currentBilledId]) {
        prev.billedData[currentBilledId] = 0;
      }

      prev.billedData[currentBilledId]+=currentValue;
      return prev;
    },{totalBilled:0,billedData:{}})
    
    var arrBilledData = Object.keys(factsInfo.billedData).map(currentBilledDataKey => {
      var currentBilledDataVal = factsInfo.billedData[currentBilledDataKey];
      var returnBilledData = {
        value:currentBilledDataVal,
        nombre:allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBilledDataKey).data.nombre,
        porc:currentBilledDataVal/factsInfo.totalBilled
      }
      return returnBilledData;
    })

    arrBilledData = arrBilledData.sort((a,b) => b.value - a.value);

    //console.log('factsInfo',arrBilledData);

    data.dataFacturacion = arrBilledData;
  }      
}

export default {
  tipoInforme:"FacturacionClientes",
  campos: [
    //{nombre:'Nota',nombreDb:'nota',tipo:'TextField'},
    {nombre:'Empresa Emisora',nombreDb:'empresa',tipo:'SelectorOtherTable',model:empresaModel
      ,filtrarDatos:(datos) => {
        datos = datos.filter(currentDato => currentDato.data.emiteFactura)        
        return datos;
      },habilitarTodos:true,habilitarTodosLabel:"Todas las empresas",
    },

    {nombre:'Visualizar',tipo:'Button',icon:<VisibilityIcon />,exec:(data) => {data.dataFacturacion = true}},

    {nombre:'Facturacion por clientes',nombreDb:'tablaFacturacionClientes',tipo:'Tabla',
      showField:(data) => {return data.dataFacturacion!==undefined},
      processData:(data) => {
        processAllDataClientes(data);
        var columns = [{id:"nombre",label:"Nombre",align:"center"},
                       {id:"value",label:"Valor Facturado",align:"center",format:(value) => toCurrency(value)},
                       {id:"porc",label:"Porcentaje",align:"center",format:(value) => toPercentage(value)}];

        //console.log('data--->',data);
        return {columns,rows:data.dataFacturacion}
      }    
    },
    {nombre:'Gráfico facturacion por clientes',nombreDb:'graficoPastelPorClientes',tipo:'GraficoPastel',
      showField:(data) => {return data.dataFacturacion!==undefined},
      processData:(data) => {    
        
        var labels = data.dataFacturacion.map(currentCompany => currentCompany.nombre);
        var values = data.dataFacturacion.map(currentCompany => currentCompany.value);
        var colors = getRandomColors(labels.length);

        return {
          labels,
          datasets: [{
            data: values,
            backgroundColor: colors,
            hoverBackgroundColor: colors,
          }],
          /*options:{
            /*plugins: {
              labels: [{
                  render: 'value',
                  fontColor: '#fff',
                },
                {
                  render: 'value',
                  position: 'outside'
                },                
              ]
            }
          }*/
        }
      },
      labelOption:() => {
        return {plugins: {
          labels: [{
              render: 'percentage',
              fontColor: '#fff',
              precision: 2,
            },
            {
              render: function (args) {
                 // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
                 return toCurrency(args.value);
              },
              position: 'outside'
            },                
          ]
        }
      }}
    }
  ],
}
