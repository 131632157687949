import moment from 'moment-timezone';
import * as jsPDF from 'jspdf';

var getData = (data,noData) => {
  return data?data:noData;
}

var getImageData = (file) => {
  return new Promise ((resolved) => {
    var i = new Image();
    
    i.onload = (evt) => {
      //console.log('Termina',file);
      resolved([file,i]);
    }
    //console.log('Pone',file);
    i.setAttribute('crossOrigin', 'anonymous')
    i.crossOrigin="anonymous";
    i.src = file;
  })
}

export var getPdfFromBill = async (data)  => {
  return new Promise((resolve, reject) => {
    var allPromises = [];
  
    if(data.fecha.toDate) data.fecha = data.fecha.toDate();
    if(data.fechaIniPeriodo.toDate) data.fechaIniPeriodo = data.fechaIniPeriodo.toDate();
    if(data.fechaFinPeriodo.toDate) data.fechaFinPeriodo = data.fechaFinPeriodo.toDate();

    //console.log('data.billingCompanyId.data.imagenSuperior',data.billingCompanyId.data.imagenSuperior,data.billingCompanyId.data,data.billingCompanyId);
    const allImages = ["/template/template.png",data.billingCompanyId.data.imagenSuperior,data.billingCompanyId.data.imagenMarcaAgua];
    
    allImages.forEach(imgPath => {
      allPromises.push(getImageData(imgPath));
    })
  
    //console.log('Promises',allPromises);
    Promise.all(allPromises).then(allImagesData => {
      allImagesData = allImagesData.reduce((prev,current) => {
        //console.log('prev',prev);
        prev[current[0]] = current[1];
        return prev;
      },{});
      
      //console.log('allImagesData2',allImagesData);
  
      var doc = new jsPDF({format: 'letter'});      
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
  
  
      //console.log(width,'X',height);
  
      //doc.addImage(allImagesData["template/bill_template.png"], "PNG", 15, 40, 180, 180);
      doc.addImage(allImagesData[data.billingCompanyId.data.imagenMarcaAgua], "PNG", 17, 152, 185,77);
      //doc.addImage(allImagesData["template/wm.png"], "PNG", 17, 152, 185,77);
      doc.addImage(allImagesData["/template/template.png"], "PNG", 0, 0, width, height);
      doc.setFontSize(13);
      //doc.text("" + data.consecutivo, 0, 0);
  
      //console.log('data.fecha',data.fecha);
      doc.text("" + getData(data.consecutivo||"[CONSECUTIVO]"), 186, 37,{baseline:"middle",align:"center"});
      doc.text(moment(data.fecha).format('DD/MM/YYYY'), 155, 37,{baseline:"middle",align:"center"});
      doc.addImage(allImagesData[data.billingCompanyId.data.imagenSuperior], "PNG", 56-(82/2), 23-(20/2), 82, 20);
      doc.text("" + getData(data.billingCompanyId.data.direccion||"[DIRECCION]"), 16, 43,{baseline:"bottom",align:"left"});
      doc.text(getData(data.billingCompanyId.data.ciudad||"[CIUDAD]") + ", " + getData(data.billingCompanyId.data.ampliacionDireccion||"[AMPLIACION DIRECCION]") + ".", 16, 49,{baseline:"bottom",align:"left"});
      doc.text("" + getData(data.billingCompanyId.data.pais||"[PAIS]"), 16, 55,{baseline:"bottom",align:"left"});
  
      var currentY = 88;
      doc.text("" + getData(data.billedCompany.data.nombre), 25, 88,{baseline:"bottom",align:"left"});
      
      if(data.billedCompany.data.direccion) {
        currentY+=6;
        doc.text("" + getData(data.billedCompany.data.direccion), 25, currentY,{baseline:"bottom",align:"left"});
      }
  
      if(data.billedCompany.data.ciudad || data.billedCompany.data.ampliacionDireccion) {
        currentY+=6;        
        doc.text(getData(data.billedCompany.data.ciudad) + ", " + getData(data.billedCompany.data.ampliacionDireccion) + ".", 25, currentY,{baseline:"bottom",align:"left"});
      }
  
      if(data.billedCompany.data.telefono) {
        currentY+=6;        
        doc.text(getData(data.billedCompany.data.telefono), 25, currentY,{baseline:"bottom",align:"left"});
      }
  
      if(data.billedCompany.data.pais) {
        currentY+=6;        
        doc.text(getData(data.billedCompany.data.pais), 25, currentY,{baseline:"bottom",align:"left"});
      }

      //if(data.billedCompany.data.pais) {
      //  currentY+=6;        
      //  doc.text(getData(data.billedCompany.data.pais), 25, currentY,{baseline:"bottom",align:"left"});
      //}
  
      var printConcept = (str,initY) => {
        var lines = doc.splitTextToSize(str, 70);
        doc.text(lines, 56, initY,{baseline:"middle",align:"left"});
  
        //doc.text(str, 56, initY,{baseline:"middle",align:"left"});
        return initY + ((lines.length-1)*6);
      }
  
      if(data.conceptos) {
  
        var conceptos = data.conceptos;
  
        var currentY = 160;
  
        var initDate = moment(data.fechaIniPeriodo).format('DD MMMM');
        var endDate = moment(data.fechaFinPeriodo).format('DD MMMM');
  
        var total = parseFloat(data.valor);
        var remaining = total;
  
        var currencyFormat = new Intl.NumberFormat("en-US",
                        { style: "currency", currency: "USD",
                          minimumFractionDigits: 2 });
  
        for (var indexConcepto = 0; indexConcepto < conceptos.length; indexConcepto++) {
          const currentConcepto = conceptos[indexConcepto];          
          doc.text("01", 34, currentY,{baseline:"middle",align:"center"});
          doc.text("0%", 187, currentY,{baseline:"middle",align:"center"});
  
          var conceptValue = Math.round(total*parseFloat(currentConcepto.porcentaje))/100;
          if(indexConcepto===conceptos.length-1) {
            conceptValue = remaining;
          } else {
            remaining-=conceptValue;
          }
  
          doc.text(currencyFormat.format(conceptValue), 149, currentY,{baseline:"middle",align:"center"});
          currentY = printConcept(initDate + " - " + endDate + " - " + currentConcepto.data.data.concepto,currentY)
          //currentY = printConcept("Este es un concepto muy largo largo, Este es un concepto muy largo largo  Este es un concepto muy largo largo  Este es un concepto muy largo largo",currentY)
          
          currentY+=10;
        }
  
        doc.text(currencyFormat.format(total), 187, 237,{baseline:"middle",align:"center"});
      }
  
      if(data.billingCompanyId.data.web) {
        doc.setTextColor("#FFFFFF")
        doc.text(getData(data.billingCompanyId.data.web), 109, 272,{baseline:"middle",align:"center"});
      }
  
      resolve(doc);
    });
  });  
}