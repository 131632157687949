import * as React from 'react';
import dataManager from '../../app/DataManager';
const {getFileName,uniqueId} = require('../../helpers/NameUtils');


export var checkFacturas = (infoItem) => {
  var allFacturas = dataManager.getAllDataByModel("Factura");
  var allEmpresas = dataManager.getAllDataByModel("Empresa");

  var billedTo = [];
  
  allFacturas.forEach(currentBill => {
    if(currentBill.data.billedCompany.id === infoItem.id) {
      var companyName = allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBill.data.billingCompanyId).data.nombre;
      billedTo.push("De " + companyName + " (consecutivo: " + currentBill.data.consecutivo + ")");
    }
  })

  if(billedTo.length>0) {
    billedTo.unshift("No se puede eliminar porque ya existen una o mas facturas recibidas por esta empresa:");
    billedTo = billedTo.map((val,index) => <p key={index}>{val}</p>);
    return {error:true,msg:billedTo}
  }  

  ////console.log('infoItem.id',infoItem.id);

  var billingTo = allFacturas.some(currentBill => currentBill.data.billingCompanyId === infoItem.id);

  if(billingTo) {    
    return {error:true,msg:"No se puede eliminar porque la empresa ya tiene facturas emitidas."}
  }  
}

export default {
  tipoDato:"Empresa",  
  campos: [
    //{nombre:'Nro.',nombreDb:'item',tipo:'TextField'},
    {nombre:'Nombre',nombreDb:'nombre',tipo:'TextField'},
    
    //{nombre:'Fecha',nombreDb:'fecha',tipo:'DateTime'},
    {nombre:'Dirección',nombreDb:'direccion',tipo:'TextField'},
    {nombre:'Ampliación dirección',nombreDb:'ampliacionDireccion',tipo:'TextField'},    
    {nombre:'Ciudad',nombreDb:'ciudad',tipo:'TextField'},
    {nombre:'País',nombreDb:'pais',tipo:'TextField'},
    {nombre:'Página web',nombreDb:'web',tipo:'TextField'},

    {nombre:'Teléfono',nombreDb:'telefono',tipo:'TextField'},

    {nombre:'¿Esta empresa emite facturas?',nombreDb:'emiteFactura',tipo:'BoolCheck'},
    
    {nombre:'Imagen logo superior',nombreDb:'imagenSuperior',tipo:'ImageSelector'
    ,label:"Arrastra o selecciona una imagen de 970 x 240 para el logo en la parte superior de la factura Max. 5MB (jpg, png)",
    acceptedImageDimension:{exact:{w:970,h:240}},
    showField: (data) => {
        return data.emiteFactura===true;
    }},
    
    {nombre:'Imagen marca de agua',nombreDb:'imagenMarcaAgua',tipo:'ImageSelector'
    ,label:"Arrastra o selecciona una imagen de 925 x 385 para el logo en marca de agua al fondo de la factura Max. 5MB (jpg, png)",
    acceptedImageDimension:{exact:{w:925,h:385}},
    showField: (data) => {
        return data.emiteFactura===true;
    }},
    
    {nombre:'A esta empresa le facturan?',nombreDb:'recibeFactura',tipo:'BoolCheck'},
  ],
  getDefaultItem: (arr_data) => {
    return {
      'nombre': '',
      'direccion': '',
      'ampliacionDireccion': '',
      'ciudad': '',
      'pais': '',
      'web': '',
      'imagenSuperior': '',
      'imagenMarcaAgua': '',
      'telefono':'',
      emiteFactura: false,
      recibeFactura: true,
    }
  },
  getNombre: (data) => {return data.nombre},
  getNombreSub: (data) => {return data.emiteFactura?'[EMISOR]':''},
  generateId: (data) => {
    return getFileName(data.nombre)+uniqueId();
  },
  //Cuando se listan todos los datos de este tipo en un select, como debe aparecer
  getInListNombre: (data) => {
    return data?data.nombre + (data.documento?` (${data.documento})`:""):'';
  },
  transformDataForSave: async (info) => {
    //console.log('Guardando cliente',info);

    var procesarImagen = async (key) => {
      if(info.data[key]) {
        if(!info.data[key].startsWith) {
          var imgBlob = info.data[key][0];
          
          //console.log('BLOB',imgBlob);
      
          if(imgBlob && imgBlob.length > 0) {
            var imagenUrl = await dataManager.uploadFile(imgBlob[0],info.id+'_'+key+'_'+'.jpg');
            info.data[key] = imagenUrl;
          }
          else {
            info.data[key] = '';
          }
        }
      }
    }

    await procesarImagen('imagenSuperior');
    await procesarImagen('imagenMarcaAgua');

    if(info.data.emiteFactura) {
      if(
      info.data.imagenSuperior==='' || info.data.imagenSuperior===undefined ||
      info.data.imagenMarcaAgua==='' || info.data.imagenMarcaAgua===undefined ||
      info.data.nombre==='' || info.data.nombre===undefined ||
      info.data.direccion==='' || info.data.direccion===undefined ||
      info.data.ampliacionDireccion==='' || info.data.ampliacionDireccion===undefined ||
      info.data.ciudad==='' || info.data.ciudad===undefined ||
      info.data.pais==='' || info.data.pais===undefined ||
      //info.data.web==='' || info.data.web===undefined ||
      info.data.telefono==='' || info.data.telefono===undefined 
      ) {
        return {error:"Si la empresa emite factura debe diligenciar todos los datos."};
      }
    }
  },
  /**
   * infoItem -> {id,data,ref}
   */
  delete:async (infoItem) => {
    
    var check = checkFacturas(infoItem);
    if(check) return check;

    await dataManager.removeDataFromFirestoreByRef(infoItem.ref,'Empresa');
    return {success:true};    
  }
}
