import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import {  Route } from 'react-router-dom'

import MenuBar from '../components/MenuBar'

import { withContext } from '../app/AppContext';

import GestorDatos from './editorDatos/GestorDatos';
import empresaModel from '../data_loaders/models/Empresa';
import facturaModel from '../data_loaders/models/Factura';
import conceptoModel from '../data_loaders/models/Concepto';
import dataManager from '../app/DataManager';

import BillingCompanies from '../screens/BillingCompanies'
import Informes from '../screens/Informes'

const {getLastRoute, goToParent} = require('../helpers/UrlUtils');



var is = require('is_js');

//import FwModalLoader from './FwUtils/FwModalLoader';
//import FwModalWindow from './FwUtils/FwModalWindow';
//import AppBarAdmin from "./AppBarAdmin";



const styles = theme => ({ 
  screenContainer: {
    width: '100%',
    height: 'calc( 100% - 64px )',
    display: 'flex',
    padding: '16px',
    position: 'relative',
    justifyContent: 'center',
    marginTop: '64px',
  },

  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
});

class Home extends Component {

  state = {
  }


  constructor(props) {
    super(props);
    this.state = {datos:dataManager.getAllDataByModel('Empresa')}
  }

  componentWillUnmount() {
    //console.log('Pide que desmonte el Home',);
  }

  onMenuClick = (menu) => {
    if(menu.accion==="GoTo") {
      //console.log('Mete en history a ',"/home/"+menu.parametro);
      this.props.history.push("/home/"+menu.parametro);     
      return true;
    }

    if(menu.accion==="CloseSession") {
      this.context.closeSession();
    }
  }

  getBillingCompanies = (props) => {
    return (<BillingCompanies {...props} />);
  }

  getGestorEmpresa = (props) => {
    return <GestorDatos
      key={"Empresa"}
      model={empresaModel}
      data={dataManager.getAllDataByModel("Empresa")}
      route={"companies"}
      {...props}
    />
  }
  
  getGestorConcepts = (props) => {
    return <GestorDatos
      key={"Concepto"}
      model={conceptoModel}
      data={dataManager.getAllDataByModel("Concepto")}
      route={"concepts"}
      {...props}
    />
  }

  getBillsForCompany = (props) => {
    //console.log("props__",this.props);
    //console.log("ES",getLastRoute(this.props));
    
    var companyId = this.props.match.params.id;

    //console.log('La data de compañia adicional queda en',companyId);

    var allBills = dataManager.getAllDataByModel("Factura");

    //console.log('Todas las facturas',allBills);

    allBills = allBills.filter((currentBill) => {
      return currentBill.data.billingCompanyId === companyId;
    })

    return <GestorDatos
      key={"Factura"}
      model={facturaModel}
      data={allBills}
      additionalData={companyId?{billingCompanyId:companyId}:undefined}
      route={"bills"}
      {...props}
    />
  }

  getEstadisticas = (props) => {
    return <Informes
      key={"Informes"}
      {...props}
    />
  }

  render() {
    const { classes } = this.props;

    //console.log('this Home',this.props,this.state,this.context);

    //var data = dataManager.getAllDataByModel('Empresa');

    var menuParams = {}

    if(!this.props.match.params.screen) {
      menuParams.title = "Lista de empresas";
    }

    if(this.props.match.params.screen==="bills" && this.props.match.params.id) {
      if(this.props.match.params.id) {
        var allCompanies = dataManager.getAllDataByModel("Empresa");

        allCompanies.some(currentCompany => {
          if(currentCompany.id===this.props.match.params.id) {
            menuParams.title = "Facturas de " + currentCompany.data.nombre;
            return true;
          }
        });       
      }
      if(this.props.match.params.objectId) {
        if(this.props.match.params.objectId==="create") {
          menuParams.title = "Creación de factura";
        }
        else {
          var allBills = dataManager.getAllDataByModel("Factura");

          allBills.some(currentBill => {
            if(currentBill.id===this.props.match.params.objectId) {
              menuParams.title = "Edición de factura Nro." + currentBill.data.consecutivo;
              return true;
            }
          });      
        }
      }

      menuParams.showArrow = true;
      menuParams.onCornerIconClick = () => {
        //console.log('HOLA GOL',this.context);
        if(this.context.fnMenuBack) {
          return this.context.fnMenuBack();
        }
        else {
          goToParent(this.props);
          //console.log(this.props);
          return true;
        }
      };
    }

    return (
      <div className={classes.root}>
        <MenuBar onMenuClick={this.onMenuClick} {...menuParams} />
        <div className={classes.screenContainer}>
          <Route exact path='/home' component={this.getBillingCompanies} />          
          <Route exact path='/home/companies' component={this.getGestorEmpresa}/>          
          <Route path='/home/companies/:id' component={this.getGestorEmpresa}/>
          <Route exact path='/home/concepts' component={this.getGestorConcepts}/>          
          <Route path='/home/concepts/:id' component={this.getGestorConcepts}/>         
          <Route exact path='/home/bills' component={this.getBillingCompanies}/>
          <Route exact path='/home/bills/:idCompany' component={this.getBillsForCompany}/>
          <Route path='/home/bills/:idCompany/:id' component={this.getBillsForCompany}/>
          <Route exact path='/home/estadisticas' component={this.getEstadisticas}/>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(Home)));
