import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import dataManager from '../../app/DataManager';
import { withContext } from '../../app/AppContext';
//import ItemProductoIndividual from './ItemProductoIndividual';
import { lightGreen } from '@material-ui/core/colors';

const clonedeep = require('lodash.clonedeep');


const styles = theme => ({
  item: {
    display: 'flex',
    margin: theme.spacing(1),
    alignItems: 'center',
  },

  itemIndividual: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: theme.spacing(8),
  },


  textField: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%',
    minWidth: '105px',
  },

  fab: {
    marginRight: theme.spacing(1),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
});

class ItemConcepto extends React.Component {

  state = {
    max_cantidad: -1,
    individualDesplegado: false,
    tipoProductos: [], //Este es el array de productos individuales traido de bd
  }

  onChangePorcentaje = (val) => {    
    //console.log("Cambio a ",val,val.target.value);
    if(isNaN(val.target.value)) return;
    this.props.setItemData(this.props.item.id,{porcentaje:val.target.value});
  }

  onChangeItem = (evt,val) => {
    //console.log('Entra a un change',val,'para',this.props.item.id);
    this.setState({max_cantidad:val.data.cantidadNoAlquilado+this.props.getCantidadAdicional(val.id),individualDesplegado:false})
    if(val===null) {
      this.props.setItemData(this.props.item.id);
      //this.props.setCantidadMaxima(this.props.index,-1);
    }
    else {
      this.props.setItemData(this.props.item.id,val);
      //this.props.setCantidadMaxima(this.props.index,val.cantidad);
      //console.log("Cambio a",val);
    }
  }

  onChangeIndividual = (idIndividual,val) => {
    //console.log("Cambia interno",idIndividual,val);
    this.props.setItemIndividualData(this.props.item.id,idIndividual,val);
  }

  onClickAsignar = async () => {
    //console.log('Data',this.props.item.data.id);

    if(this.state.individualDesplegado) {
      this.setState({individualDesplegado:false});
    }
    else {
      if(this.props.fnDevolucion) {
        var productosIndividuales = this.props.fnDevolucion(this.props.item.data.id);
        //console.log('Llega 2',productosIndividuales);
        productosIndividuales.sort((a,b) => {
          if(a.data.nombre < b.data.nombre) { return -1; }
          if(a.data.nombre > b.data.nombre) { return 1; }
          return 0;
        })

        this.setState({tipoProductos:productosIndividuales,individualDesplegado:true});
      }
      else {
        this.context.showLoader(true,"Cargando...");
        //dataManager.getSubCollectionById("TipoProducto",this.props.item.data.id,"productos",["cantidadNoAlquilado",">",0]).then((productosIndividuales) => {
        dataManager.getSubCollectionById("TipoProducto",this.props.item.data.id,"productos").then((productosIndividuales) => {
          this.context.showLoader(false);
          //console.log('Llega ',productosIndividuales);
          productosIndividuales.sort((a,b) => {
            if(a.data.nombre < b.data.nombre) { return -1; }
            if(a.data.nombre > b.data.nombre) { return 1; }
            return 0;
          })
          //console.log('Productos individuales',productosIndividuales);
          this.setState({tipoProductos:productosIndividuales,individualDesplegado:true});
        });
      }
    }

    ////console.log('Arr',arrData.then);
  }

  filtrarDatos = (seleccionado) => {
    //console.log('Filtrar con seleccionado',seleccionado);

    var data = this.state.tipoProductos;

    //console.log("Mi item",this.props.item);

    var idTipoProductoUsados = this.props.item.itemsIndividuales.map((value) => {
      return value.data.id;
    }).filter(value => value!==undefined);

    data = data.filter((value) => {

      //Si la cantidad no alquilada es cero entonces no lo muestra ya que no tiene sentido que
      //seleccione esa
      if((value.data.cantidadNoAlquilado+this.props.getCantidadAdicional(value.id))==0) return false;

      var include = idTipoProductoUsados.includes(value.id);

      //Si si esta entre los usados mira que no sea que el itemConcepto que llama esta función sea el que
      //lo este usando
      if(include) {
        return value.id===seleccionado.data.id;
      }

      return true;
    });

    data = data.map((value) => {
      return {id:value.id,data:value.data};
    });

    return data;

    /*var idTipoProductoUsados = this.state.data.map((value) => {
      return value.data.id;
    }).filter(value => value!==undefined);

    var data = this.state.tipoProductos;
    //console.log('pref',idTipoProductoUsados,"--",data);

    data = data.filter((value) => {
      var include = idTipoProductoUsados.includes(value.id);

      //Si si esta entre los usados mira que no sea que el itemConcepto que llama esta función sea el que
      //lo este usando
      if(include) {
        return value.id===seleccionado.data.id;
      }

      return true;
    });

    data = data.map((value) => {
      return {id:value.id,data:value.data};
    });

    return data;*/
  }

  filterOptions = function(options, state) {

    var values = state.inputValue.split(" ");

    if(state.inputValue==="") {
      return options;
    }

    return options.filter((option) => {
      return values.every(value => {
        var optionData = option.data?(option.data.nombre?option.data.nombre.toLowerCase():""):"";
        return optionData.includes(value.toLowerCase())
      })
    })

  }

  render() {
		const { classes, items } = this.props;

    ////console.log('Carga items',items);


    //console.log('Tiene propall selected ',this.props.state);
    //console.log('Tiene prop selected ',this.props.item);

    var selected = this.props.item.data;
    if(selected.id===undefined) selected="";


    var noRefItems = this.props.filtrarDatos(this.props.item);

    /*Esto es para que si solo queda un item por seleccionar lo seleccione automaticamente*/
    if(noRefItems.length===1 && selected==="") {
      this.onChangeItem(null,noRefItems[0]);
    }

    var esIndividual = false;
    var individualDiligenciado = true;

    ////console.log('Renderiza TIPO',this.props.item,'todos son',noRefItems,this.props.item===noRefItems[1]);

    if(this.props.item.data.data!==undefined && this.props.item.data.data.alquilerIndividual===true) {
      esIndividual = true;

      //Si alguna de las posiciones individuales no tiene ya el objeto escogido entonces aún no esta todo diligenciado
      for (var index = 0; index < this.props.item.cantidad; index++) {
        if(!this.props.item.itemsIndividuales[index].data.id) {
          individualDiligenciado = false;
          break;
        }
      }
    }

    ////console.log('El selected es',selected);
    ////console.log('item',this.props.item);

    //InputProps={{classes:{input:'5%'}}}
    return (
      <React.Fragment>
        <div className={classes.item} key={this.props.id}>
          <Fab color="primary" aria-label="add" disabled={this.props.disabled} className={classes.fab} onClick={() => {
            //console.log('Borrar a',this.props.index);
            this.props.borrarItem(this.props.index);
          }}>
            <DeleteIcon />
          </Fab>
          <Autocomplete
            key={"auto"+this.props.id}
            onChange={this.onChangeItem}
            options={noRefItems}
            value={selected}
            filterOptions={this.filterOptions}
            getOptionLabel={(option) => {              
              if(option==="") return "";
              return option.data.concepto;
            }}
            disabled={this.props.disabled}
            style={{ width: '60%' }}
            renderInput={params => (
              <TextField {...params} label="Concepto" variant="outlined" fullWidth />
            )}
          />
          <div>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              label={"Porcentaje"+(this.state.max_cantidad>=0?" (max. "+this.state.max_cantidad+")":"")}
              value={this.props.item.porcentaje}
              onChange={this.onChangePorcentaje}
              disabled={this.props.disabled}
              margin="normal"
              variant="outlined"              
            />
          </div>
          {esIndividual && <Button
            style={{color:individualDiligenciado?"green":"red"}}
            variant="outlined"
            color="primary"
            className={classes.button}
            endIcon={individualDiligenciado?<CheckCircleIcon style={{color:individualDiligenciado?"green":"red"}}/>:<CancelIcon />}
            onClick={this.onClickAsignar}
          >
            {this.props.disabled?"Ver":"Asignar"}
          </Button>}
        </div>        
      </React.Fragment>
    )
  }
}

export default withStyles(styles,{ withTheme: true })(withContext(ItemConcepto))
