import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';

import ImageUploader from "react-images-upload";

import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import GestorDatos from './GestorDatos';
import dataManager from '../../app/DataManager';

import { withContext } from '../../app/AppContext';

import SelectorConceptos from '../components/SelectorConceptos';

const clonedeep = require('lodash.clonedeep');

const styles = theme => ({
  screenWindow: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  dataInnerContainer: {
    marginTop: '16px',
    width: '100%',

    //https://material-ui.com/customization/breakpoints/
    //sm(600) md(960) lg(1280)
    [theme.breakpoints.up('lg')]: {
      width: '80%',      
    },
  },

  dataContainer: {
    //margin: theme.spacing(2,0,2,3),
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
    justifyContent: 'center',
  },

  buttonContainer: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid darkgray',
  },

  textFieldClass: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 'calc( 100% - ' + theme.spacing(4) +'px )',
    flex: 1,
    display: 'inline-flex',

    [theme.breakpoints.up('sm')]: {
      width: '45%',
    },
  },

  button: {
    margin: theme.spacing(2),
  },

  fullButton: {
    margin: theme.spacing(2),
    width: 'calc( 100% - ' + theme.spacing(4) +'px )',
  },

  fullWidth: {
    width: '100%',
  },

  selector: {    
    width: 'calc( 100% - 32px )',
    border: 'solid 1px rgba(0, 0, 0, 0.27)',
    padding: '15px',
    marginTop: '15px',
    borderRadius: '4px',
    marginLeft: '16px',

    [theme.breakpoints.up('sm')]: {
      width: 'calc( 90% + 32px )',
    },
  },

  imgField: {
    padding: theme.spacing(0,2),
    border: 'solid 1px rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    textAlign: 'center',
    '& .fileContainer': {
      padding: 0,  
      background: 'transparent',
      boxShadow: 'none',
      '& p': {
        fontSize: '14px',
      },
      '& .chooseFileButton' : {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: theme.spacing(1,2),
        textTransform: 'uppercase',
        lineHeight: '1.75',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      }
    }
  }
});

class EditorDatos extends React.Component {

  constructor(props) {
    super(props)

    //console.log('props',this.props);
       

    var dataClone = dataManager.deleteRefs(this.props.reg.data);

    Object.keys(dataClone).forEach((key) => {
      ////console.log('Key',key,'data',dataClone[key],'toDate',dataClone[key].toDate);
      if(dataClone[key] && dataClone[key].toDate) {
        dataClone[key] = dataClone[key].toDate();
      }
    })

    this.state = {data:dataClone,dependantSelectorData:{}};
    //console.log('this state queda',this.state);

    if(this.props.model.getMenuTitleInEdition) {
      window.app.appMenuOverride(this.props.model.getMenuTitleInEdition(this.props.reg),this.onAppMenuBack);
    }

    //Si no es un item nuevo sino editado
    if(this.props.isCreation) {
      this.hasBeenModified = true;
      //this.state = this.props.itemInEdition.data();
    }
  }

  componentDidMount = () => {
    this.context.appMenuBackOverride(this.closeEdition);
  }

  onAppMenuBack = () => {
    this.closeEdition();
  }

  closeEdition = (forceClose=false) => {
    if(!forceClose) {
      if(this.hasBeenModified) {
        this.context.showDialog({title:"Confirmación",message:"Si cancelas perderás los cambios, ¿deseas cancelar?",buttons:[
          {label:"Si",callback:() => {
            if(this.props.model.getMenuTitleInEdition) this.context.removeAppMenuOverride();
            this.props.closeEdition();
          }},
          {label:"No"}
        ]});
      }
      else {
        if(this.props.model.getMenuTitleInEdition) this.context.removeAppMenuOverride();
        this.props.closeEdition();
      }
    }
    else {
      if(this.props.model.getMenuTitleInEdition) this.context.removeAppMenuOverride();
      this.props.closeEdition();
    }
  }

  setValue = (nombreDb,value) => {
    this.hasBeenModified = true;

    var newData = this.state.data;
    newData[nombreDb] = value;

    this.setState({data:newData});
  }

  //data es el data del Dependant en el model
  openSubPanel = async (data) => {

    var allSubData = this.state.data[data.key];//await dataManager.getSubCollection(data,this.props.reg,this.props.model);



    //console.log('Obtiene busdata00',this.state.data);
    //console.log('Obtiene busdata0',data);
    //console.log('Obtiene busdata',allSubData);

    //Resulta que cuando recibe la informacion en los props, tiene que clonar el objeto para poder tener los cambios
    //Pero la clonacion no soporta referencias circulares y el ref de Firestore las tiene, razon por la cual el objeto
    //copia no tiene la propiedad ref, entonces cuando se va a abrir la lista por ejemplo de obras, toca tomar los datos
    //y traerse las referencias desde el objeto original de props
    allSubData.forEach((currentSubData) => {
      ////console.log('LLega con ',this.props);
      var refProp = this.props.reg.data[data.key].filter(propData => propData.id===currentSubData.id);
      if(refProp.length>0) {
        currentSubData.ref = refProp[0].ref;
      }
      ////console.log('Encontró a',refProp);
    })

    var panelData = {
      key:data.key,
      model: data.model,
      data: allSubData,
      appMenuOverride: data.appMenuOverride,
    }

    //console.log('Click a subpanel con',data);
    this.setState({gestorOpen:true,subPanelData:panelData});
  }

  closeSubPanel = () => {
    this.setState({gestorOpen:false});
    this.props.gestorDatos.tryRestoreAppMenuBack();
  }

  getAsyncData = async (value) => {
    if(this.waitingForData) return;

    this.waitingForData = true;
    var retData = await value.model.getParentSelectorData(this.state.data[value.depentantParent]);

    //console.log('Llego retData',retData);

    var allDependantSelectorData = this.state.dependantSelectorData;
    allDependantSelectorData[value.nombre] = {
      idCurrentParent:this.state.data[value.depentantParent].id,
      dependantSelectorData:retData,
    }

    //console.log('Queda',allDependantSelectorData,"y",this.state.data[value.nombreDb]);
    //Si no hay algo seleccionado en el campo a traer entonces selecciona la posicion 0
    //esto se valida porque por ejemplo cuando se carga una remisión ya guardada entonces el campo
    //ya esta diligenciado y no se debe cambiar
    if(this.state.data[value.nombreDb]===undefined||this.state.data[value.nombreDb]==="") {
      this.setValue(value.nombreDb,retData[0]);
    }
    else {
      //No obstante puede que ya haya algo en el campo e igual se deba seleccionar el primero
      //esto se da en el caso cuando ya se habia escogido por ejeplo la obra 1 pero se cambia el cliente
      //en ese caso lo que hay seleccionado aca en la obra no pertenece al cliente entonces se debe borrar el campo
      if(!retData.some(val => val.id===this.state.data[value.nombreDb].id)) {
        this.setValue(value.nombreDb,retData[0]);
      }
    }

    this.setState({dependantSelectorData:allDependantSelectorData})
    this.waitingForData = false;
  }

  filterOptions = function(options, state) {

    var values = state.inputValue.split(" ");

    if(state.inputValue==="") {
      return options;
    }

    return options.filter((option) => {
      return values.every(value => {
        var optionData = option.data?(option.data.nombre?option.data.nombre.toLowerCase():""):"";
        return optionData.includes(value.toLowerCase())
      })
    })

  }

  getCampos = () => {
    const { classes,model } = this.props;

    ////console.log('GETCAMPOS',this.state.data);

    return model.campos.map((value,index) => {

      if(model.shouldBeDisabled) {
        value.allowModify = model.shouldBeDisabled(value,this.state.data);
      }

      //Si tiene una función que define si se muestra o no el campo
      //Ej, El botón de ver productos individuales para un TipoProducto solo se muestra
      //Si esta chequeado el check de Seguimiento individual.
      if(value.showField!==undefined) {
        if(!value.showField(this.state.data)) return;
      }

      if(value.tipo==="SelectorOtherTable") {
        var dataOtherTable = dataManager.getAllDataByModel(value.model.tipoDato);

        dataOtherTable = dataOtherTable.map((value) => {
          return {id:value.id,data:value.data};
        })

        if(value.filterData) {
          dataOtherTable = value.filterData(dataOtherTable,this.state.data,this.props.additionalData)
        }

        //console.log('La info de la otra tabla',value.model.tipoDato,dataOtherTable);
      }

      if(value.tipo==="DependantSelector") {
        //console.log('parent',value.depentantParent,"y mi",value.nombre);
        //console.log('Para el parent',this.state.data[value.depentantParent],"para mi",this.state.data[value.nombreDb],this.state.dependantSelectorData[value.nombre]);
        //Si no se ha seleccionado el dato padre entonces no se pinta el componente
        if(!this.state.data[value.depentantParent] || this.state.data[value.depentantParent]==="") return;

        //Si no hay informacion aún no muestra el campo
        if(!this.state.dependantSelectorData[value.nombre]) {
          this.getAsyncData(value);
          if(this.state.data[value.nombreDb]===undefined || this.state.data[value.nombre]==="") return;
        }
        else {
          //Si la informacion dependiente que se trajo de firestore si es la del cliente seleccionado entonces la muestra
          if(this.state.data[value.depentantParent].id === this.state.dependantSelectorData[value.nombre].idCurrentParent) {
            dataOtherTable = this.state.dependantSelectorData[value.nombre].dependantSelectorData;
          }
          //Si no, entonces pide que se traiga y no muestra aún el campo
          else {
            this.getAsyncData(value);
            return;
          }
        }


        //var dataOtherTable = value.model.getParentSelectorData(this.state.data[value.depentantParent]);
        ////console.log('Para dependant',value.depentantParent,this.state.data,dataOtherTable);
        //if(!dataOtherTable) return;
      }

      ////console.log('Va a mirar para ',value, 'y',this.state);
      switch (value.tipo) {
        case 'Hidden': return null;

        case 'TextField':
            return <TextField
              autoFocus={index===0}
              key={value.nombre}
              id={"outlined-basic-"+value.nombre}
              className={classes.textFieldClass}
              label={value.nombre}
              margin="normal"
              variant="outlined"
              value={this.state.data[value.nombreDb]}
              onChange={event => {
                if((value.validator && value.validator(event.target.value)) || !value.validator) {
                  this.setValue(value.nombreDb,event.target.value)
                }
              }}
              disabled={value.allowModify===false}
              type={value.onlyNumber?"number":""}
            />
        
        case 'TextFieldMultiLine':
            return <TextField
              autoFocus={index===0}
              key={value.nombre}
              id={"outlined-multiline-"+value.nombre}
              label={value.nombre}
              multiline
              rows="4"
              className={classes.textFieldClass}
              margin="normal"
              variant="outlined"
              value={this.state.data[value.nombreDb]}
              onChange={event => {this.setValue(value.nombreDb,event.target.value)}}
              disabled={value.allowModify===false}
            />

        case 'BoolCheck':
            return <FormControlLabel
              key={value.nombre}
              control={
                <Checkbox checked={this.state.data[value.nombreDb]} onChange={event => {this.setValue(value.nombreDb,event.target.checked)}} value={this.state.data[value.nombreDb]} />
              }
              label={value.nombre}
              className={classes.fullButton}
              disabled={value.allowModify===false}
            />

        case 'Dependant':
            return <Button
              key={value.nombre}
              variant="contained"
              color="primary"
              className={classes.fullButton}
              onClick={() => {
                this.openSubPanel(value.data);
              }}
            >
              {value.nombre}
            </Button>

        case 'DateTime':
            return <MuiPickersUtilsProvider key={value.nombre} utils={MomentUtils} locale={'es-us'}>
              <div className={classes.textFieldClass}>
                <DateTimePicker
                  format="DD/MMM/YYYY h:mm a"
                  id="date-picker-inline"
                  value={this.state.data[value.nombreDb]}
                  onChange={date => {this.setValue(value.nombreDb,date?date.toDate():new Date())}}
                  className={classes.fullWidth}
                  label={value.nombre}
                  inputVariant="outlined"
                  disabled={value.allowModify===false}
                  helperText={""}
                />                
              </div>
            </MuiPickersUtilsProvider>
          
        case 'Date':
            return <MuiPickersUtilsProvider key={value.nombre} utils={MomentUtils} locale={'es-us'}>
              <div className={classes.textFieldClass}>
                <KeyboardDatePicker
                  format="DD/MM/YYYY"
                  id="date-picker-inline"
                  value={this.state.data[value.nombreDb]}
                  onChange={date => {this.setValue(value.nombreDb,date?date.toDate():new Date())}}
                  className={classes.fullWidth}
                  label={value.nombre}
                  inputVariant="outlined"
                  disabled={value.allowModify===false}
                  helperText={""}
                />                
              </div>
            </MuiPickersUtilsProvider>

        case 'SelectorOtherTable':
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Autocomplete
                style={{width: '100%',}}
                key={value.nombre}
                onChange={(evt,val) => {this.setValue(value.nombreDb,val)}}
                options={dataOtherTable}
                value={this.state.data[value.nombreDb]}
                disabled={value.allowModify===false}
                filterOptions={this.filterOptions}
                getOptionLabel={(option) => {
                  return option.data?value.model.getInListNombre(option.data):"";
                  //return value.model.getInListNombre(option.data);
                  ////console.log('Con opt ',option);
                  //return option.nombre + " ("+option.cantidad+")"
                }}
                renderInput={params => (
                  <TextField {...params} label={value.nombre} variant="outlined" fullWidth error={false}/>
                )}
              />
            </div>

        case 'DependantSelector':
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Autocomplete
                key={value.nombre}
                onChange={(evt,val) => {this.setValue(value.nombreDb,val)}}
                options={dataOtherTable}
                value={this.state.data[value.nombreDb]}
                disabled={value.allowModify===false}
                filterOptions={this.filterOptions}
                getOptionLabel={(option) => {
                  return option.data?value.model.getInListNombre(option.data):"";
                }}
                renderInput={params => (
                  <TextField {...params} label={value.nombre} variant="outlined" fullWidth error={false}/>
                )}
              />
            </div>

        case 'ImageSelector': 
            return <div key={value.nombre} className={classes.textFieldClass}>
              <ImageUploader
                key={"imgSelector"+value.nombreDb}
                className={classes.imgField}
                singleImage={true}
                withPreview={true}
                withIcon={false}
                buttonText={value.nombre}
                onChange={(pictureFiles, pictureDataURLs) => { this.setValue(value.nombreDb,[pictureFiles,pictureDataURLs]); }}
                imgExtension={[".jpg", ".png"]}
                maxFileSize={5242880}
                label={value.label}
                acceptedImageDimension={value.acceptedImageDimension}
                fileSizeError={"La imagen debe pesar menos de 5MB"}
                fileTypeError={"Solo se soportan imagenes en formato png o jpg"}
                fileDimensionError={"Tiene unas dimensiones en pixeles diferentes a las solicitadas"}
                defaultImages={this.getDefaultImage(value.nombreDb)}              
              />
            </div>   
            
        case 'SelectorConceptos':
            return <div key={value.nombre} className={classes.selector}>
              <Typography variant="subtitle1" className={classes.marginRight}>
                {value.nombre}
              </Typography>
              <SelectorConceptos
                data={this.state.data[value.nombreDb]}
                key={value.nombre}
                disabled={value.allowModify===false}
                onChange={(val) => {this.setValue(value.nombreDb,val)}}
              />
            </div>
        
        default:
          break;
      }
    })
  }

  getDefaultImage = (nombreDb) => {
    
    if(!this.state.data[nombreDb]) {
      return [];
    }
    else if(typeof this.state.data[nombreDb] === 'string') {
      //console.log("Retornar POR STRING",[this.state.data[nombreDb]]);
      
      return [this.state.data[nombreDb]];
    }
    else {
      return this.state.data[nombreDb]?.[1]
    }
    //console.log('lo que hay es',this.state.data[nombreDb]);    
    //this.state.data[value.nombreDb]?.[0] || []

  }

  guardarItem = () => {
    if(!this.props.subCollection) {
      this.context.showDialog({title:"Confirmación",message:"¿Estas seguro de guardar los cambios?",buttons:[
        {label:"Si",callback:this.onConfirmacionGuardarItem},
        {label:"No"}
      ]});
    }
    else {
      this.onConfirmacionGuardarItem();
    }
  }

  onConfirmacionGuardarItem = async () => {
  /*  const { model,itemInEdition } = this.props;

    var dataClone = itemInEdition.data();

    this.context.getModel(model).campos.map((value,index) => {
      if(value.tipo!=='Dependant') {
        dataClone[value.nombreDb] = this.state[value.nombreDb];
      }
      else {

      }
    });

    this.context.showLoader(true,"Guardando información",async () => {
      await itemInEdition.ref.set(dataClone);
      this.props.closeEdition();
    });*/

    //console.log('CLIC GUARDAR CON',this.props);

    //Si viene esto es porque es un Editor de un SubItem
    if(this.props.subCollection) {
      this.fnGuardar(this.props.subCollection,{id:this.props.reg.id,data:this.state.data,ref:this.props.reg.ref});
    }
    //Guardado real en dataManager
    else {
      //console.log('ENTRA AL ELSE',this.props.reg);
      if(this.props.model.getMenuTitleInEdition) this.context.removeAppMenuOverride();
      this.props.fnGuardar(this.props.reg.key,{id:this.props.reg.id,data:this.state.data,ref:this.props.reg.ref})
    }
  }

  fnGuardar = (key,info) => {
    //console.log("GUARDAR",key,info," PARA ",this.props.reg);

    if(this.props.subCollection) {
      //console.log('LLAMA NEXT GUARDAR',);
      info.shouldBeSaved = true;
      this.props.fnGuardar(this.props.subCollection,info);

      if(this.props.model.msgOnSave) {
        this.context.showDialog({title:"Confirmación",message:this.props.model.msgOnSave});
      }
    }
    else {
      //console.log('DETIENE GUARDAR tiene',this.state.data);
      //console.log('El submodel abierto es',key);

      this.hasBeenModified = true;
      var dataForSubcollection = this.state.data[key];

      //Flag para saber si era una edicion o si es uno nuevo
      var wasEdited = false;

      for (var index = 0; index < dataForSubcollection.length; index++) {
        const element = dataForSubcollection[index];

        if(element.id===info.id) {
          element.data = info.data;
          element.shouldBeSaved = info.shouldBeSaved;
          wasEdited = true;
          break;
        }
      }

      if(!wasEdited) {
        dataForSubcollection.push({id:info.id,data:info.data,shouldBeSaved:true})
      }

      var panelData = this.state.subPanelData;
      panelData.data = dataForSubcollection;

      //console.log('Actualiza subPanelData a',panelData);
      this.setState({subPanelData:panelData});
    }
  }

  //Llamado para que borre un item nuevo de la lista (Solo se llama para items nuevos)
  fnBorrar = async (infoItem) => {
    //console.log('Llega en Editordatos a borrar',infoItem,'con',this.props.subCollection);
    //if(this.props.subCollection) {
      var dataForSubcollection = this.state.subPanelData.data.filter(currentData => currentData.id !== infoItem.id);
      var panelData = this.state.subPanelData;
      panelData.data = dataForSubcollection;
      this.setState({subPanelData:panelData});
    //}
  }

  onGestorDatosClose = () => {

  }

  modelComunication = (data) => {
    //console.log('data',data);
    switch (data.command) {
      case 'error':
        this.context.showErrorMessageDialog(data.info.error);
        if(data.goBack) {
          this.closeEdition(true);
        }
        break;

      case 'dialog':
        this.context.showDialog(data.info);
        break;

      case 'loader':
        this.context.showLoader(true,data.info);
        break;

      case 'greySaveButton':
        this.hasBeenModified=false;
        this.forceUpdate();
        break;

      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { subPanelData,gestorOpen } = this.state;

    //console.log('state',this.state,this.context);

    return (
      <div className={classes.screenWindow}>
        <React.Fragment>
          {!gestorOpen && <React.Fragment>
            <div className={classes.dataContainer}>
              <div className={classes.dataInnerContainer}>
                {this.getCampos()}                
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SaveIcon />}
                disabled={this.hasBeenModified!==true}
                onClick={() => {
                  this.guardarItem();
                }}
              >
                Guardar
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<CancelIcon />}
                onClick={() => {
                  this.closeEdition();
                }}
              >
                {this.props.model.processCancelButtonLabel?this.props.model.processCancelButtonLabel(this.state.data):"Cancelar"}
              </Button>
              {this.props.model.getAdditionalButtons && this.props.model.getAdditionalButtons(this.state.data).map((val,index) => {
                return <Button
                  key={"butAdd"+index}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={val.icon}
                  disabled={val.active!==true}
                  onClick={async () => {
                    await val.fn({id:this.props.reg.id,data:this.state.data,ref:this.props.reg.ref},this.props.reg,this.modelComunication);
                  }}
                >
                  {val.nombre}
                </Button>
              })}
            </div>
          </React.Fragment>}
          {gestorOpen && <GestorDatos
            key={subPanelData.key}
            subCollection={subPanelData.key}
            model={subPanelData.model}
            data={subPanelData.data}
            onGestorDatosClose={this.onGestorDatosClose}
            appMenuOverride={subPanelData.appMenuOverride}
            parentEditor={this}
            parentReg={this.props.reg}
            fnGuardar={this.fnGuardar}
            fnBorrar={this.fnBorrar}
            />}
        </React.Fragment>
      </div>
    )
  }
}

export default withStyles(styles,{ withTheme: true })(withContext(EditorDatos));
