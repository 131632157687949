import firebase from 'firebase';

export const firebaseConfig = {

  production:{
    apiKey: "AIzaSyAj_xxios6AdryTsJfz2Aa0_eNUefDIkc4",
    authDomain: "dacotech-facturacion.firebaseapp.com",
    databaseURL: "https://dacotech-facturacion.firebaseio.com",
    projectId: "dacotech-facturacion",
    storageBucket: "dacotech-facturacion.appspot.com",
    messagingSenderId: "155779798785",
    appId: "1:155779798785:web:6539aa8a959d8318646d00",
    measurementId: "G-07RWXC6V84"
  },
  development: {
    apiKey: "AIzaSyAj_xxios6AdryTsJfz2Aa0_eNUefDIkc4",
    authDomain: "dacotech-facturacion.firebaseapp.com",
    databaseURL: "https://dacotech-facturacion.firebaseio.com",
    projectId: "dacotech-facturacion",
    storageBucket: "dacotech-facturacion.appspot.com",
    messagingSenderId: "155779798785",
    appId: "1:155779798785:web:6539aa8a959d8318646d00",
    measurementId: "G-07RWXC6V84"
  }
};

export default firebase;
