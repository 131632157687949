import * as React from 'react';

import dataManager from '../../app/DataManager';
import empresaModel from './Empresa'
import GetAppIcon from '@material-ui/icons/GetApp';

import { getPdfFromBill } from '../../helpers/PdfDownloader';

const {uniqueId} = require('../../helpers/NameUtils');

const clonedeep = require('lodash.clonedeep');

var generarFactura = async (billData,gestorDatos) => {
  
  var data = clonedeep(billData);

  //console.log('Generar con',data);
  if(!data.billedCompany || !data.billedCompany.id) {
    //TODO Retornar error de que no hay billedCompany
    gestorDatos.context.showErrorMessageDialog("No puede generar un PDF sin asignar a quien le factura");
    return;
  }

  if(!data.consecutivo) {
    gestorDatos.context.showErrorMessageDialog("No puede generar un PDF sin asignar un número consecutivo");
    return;
  }

  if(!data.valor) {
    gestorDatos.context.showErrorMessageDialog("No puede generar un PDF sin el valor de la factura");
    return;
  }

  //Se trae la ultima información de la empresa
  var dataBilledCompany = dataManager.getDataFromModelById("Empresa",data.billedCompany.id);  
  data.billedCompany.data = dataBilledCompany;

  var dataBillingCompany = dataManager.getDataFromModelById("Empresa",data.billingCompanyId);  
  
  data.billingCompanyId = {data:dataBillingCompany};
  
  //console.log('data',data);

  var doc = await getPdfFromBill(data);
  doc.save('factura.pdf');
}

export var getTotal = (data) => {
  return data.reduce((prev,current) => prev+=isNaN(current.porcentaje)?0:current.porcentaje===""?0:parseInt(current.porcentaje),0);
}

export default {
  tipoDato:"Factura",  
  campos: [
    {nombre:'Empresa que factura',nombreDb:'billingCompanyId',tipo:'Hidden'},
    {nombre:'Consecutivo',nombreDb:'consecutivo',tipo:'TextField',allowModify:true},
    {nombre:'Fecha y hora factura',nombreDb:'fecha',tipo:'Date'},
    {nombre:'Valor factura USD (Números y punto es decimal)',nombreDb:'valor',tipo:'TextField',validator:(str) => {
      return /^([0-9]|\.)*$/g.test(str) && (str.length - str.replace(/\./g,"").length)<2;
    }},
    {nombre:'A quien factura',nombreDb:'billedCompany',tipo:'SelectorOtherTable',model:empresaModel,
      filterData: (dataOtherTable,data,additionalData) => {

        return dataOtherTable.filter( currentCompany => {
          //console.log('currentCompany44',currentCompany);
          return currentCompany.id !== additionalData.billingCompanyId && currentCompany.data.recibeFactura;
        });
      }
    },
    {nombre:'Fecha inicio periodo (DD/MM/YYYY)',nombreDb:'fechaIniPeriodo',tipo:'Date'},
    {nombre:'Fecha fin periodo (DD/MM/YYYY)',nombreDb:'fechaFinPeriodo',tipo:'Date'},
    {nombre:'Conceptos',nombreDb:'conceptos',tipo:'SelectorConceptos'},
    //{nombre:'Nro.',nombreDb:'item',tipo:'TextField'},
    //{nombre:'Nombre',nombreDb:'nombre',tipo:'TextField'},
    
    //{nombre:'Fecha',nombreDb:'fecha',tipo:'DateTime'},
    /*{nombre:'Dirección',nombreDb:'direccion',tipo:'TextField'},
    {nombre:'Ampliación dirección',nombreDb:'ampliacionDireccion',tipo:'TextField'},    
    {nombre:'Ciudad',nombreDb:'ciudad',tipo:'TextField'},
    {nombre:'País',nombreDb:'pais',tipo:'TextField'},
    {nombre:'Página web',nombreDb:'web',tipo:'TextField'},

    {nombre:'Teléfono',nombreDb:'telefono',tipo:'TextField'},

    {nombre:'¿Esta empresa emite facturas?',nombreDb:'emiteFactura',tipo:'BoolCheck'},
    
    {nombre:'Imagen logo superior',nombreDb:'imagenSuperior',tipo:'ImageSelector'
    ,label:"Arrastra o selecciona una imagen para el logo en la parte superior de la factura Max. 5MB (jpg, png)",
    showField: (data) => {
        return data.emiteFactura===true;
    }},
    
    {nombre:'Imagen marca de agua',nombreDb:'imagenMarcaAgua',tipo:'ImageSelector'
    ,label:"Arrastra o selecciona una imagen para el logo en marca de agua al fondo de la factura Max. 5MB (jpg, png)",
    //acceptedImageDimension:{greaterThan:{w:900}}
    showField: (data) => {
        return data.emiteFactura===true;
    }},
    
    {nombre:'A esta empresa le facturan?',nombreDb:'recibeFactura',tipo:'BoolCheck'},*/
  ],
  getDefaultItem: (arr_data) => {
    //console.log('Llega con ',arr_data);

    var max_consecutive = 0;

    if(arr_data) {
      arr_data.forEach(currentBill => {
        var currentConsecutive = parseInt(currentBill.data.consecutivo);
        max_consecutive = Math.max(currentConsecutive,max_consecutive);
      });
      
      max_consecutive++;
    }

    return {
      fecha: new Date(),
      billedCompany:'',
      consecutivo:max_consecutive,
      billingCompanyId:'',
      valor:0,      
      fechaIniPeriodo:new Date(),
      fechaFinPeriodo:new Date(),      
    }
  },
  getNombre: (data) => {return "Factura " + data.consecutivo},
  getNombreSub: (data) => {
    return data.billedCompany?.data?.nombre;
  },
  generateId: (data) => {
    return "bill"+uniqueId();
    //return getFileName(data.nombre)+uniqueId();
  },
  //Cuando se listan todos los datos de este tipo en un select, como debe aparecer
  getInListNombre: (data) => {
    return data.consecutivo;
  },
  sortData: (dataToSort) => {
    if(dataToSort) {
      dataToSort.sort((a,b) => a.data.consecutivo - b.data.consecutivo);
      return dataToSort;
    }
  },
  transformDataForSave: async (info) => {
    //console.log('Guardando factura',info);
    //return {error:"Esta parte no está hecha."};
    if(getTotal(info.data.conceptos)!==100) {
      return {error:"Los porcentajes deben sumar exactamente 100."}
    }

    if(info.data.conceptos.some(concepto => Object.keys(concepto.data).length===0)) {
      return {error:"Todos los campos deben tener un concepto seleccionado."}
    }

    return info;
  },
  /**
   * infoItem -> {id,data,ref}
   */
  delete:async (infoItem) => {
    /*if(dataManager.haveRemisionBy('idCliente',infoItem.id)) {
      return {error:true,msg:"No se puede eliminar porque ya existe una remisión con este cliente."}
    }
    else {*/
      await dataManager.removeDataFromFirestoreByRef(infoItem.ref,'Factura');
      return {success:true}
    //}
  },
  listAdditionalButtons: [{icon:<GetAppIcon />,callback:async (dataFactura,gestorDatos)=>{
    gestorDatos.context.showLoader(true,"Generando PDF");
    await generarFactura(dataFactura.data,gestorDatos);
    gestorDatos.context.showLoader(false);
  }}],
}
