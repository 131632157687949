import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withContext } from '../app/AppContext';

import dataManager from '../app/DataManager';

import Button from '@material-ui/core/Button';

var is = require('is_js');




const styles = theme => ({ 
  screenContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',    
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },

  root: {
    display: 'flex',
    height: '100%',
  },

  title: {
    textAlign:'center', 
    flex:1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  divImgContainer: {
    border: 'solid 2px lightgray',
    borderRadius: '15px',
    overflow: 'hidden',
    padding: theme.spacing(2),
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    width: '50%',    
    marginLeft: 'auto',
    marginRight: 'auto',
    '&:hover': {
      border: 'solid 2px dodgerblue',
    }
  },

  divAllImages: {
    width: '100%',
    overflow: 'auto',
    flex: 8,
  },

  imgContainer: {
    width: '100%',
  },

  circular: {
    outline: "0px solid transparent",
    //color: "white",
  },

  circularDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '32px',
  },

  button: {
    margin: theme.spacing(4),
  }
});

class BillingCompanies extends Component {

  state = {
    imgsLoaded: false,
  }

  componentDidMount() {
    this.getBillingCompanies();
  }

  openCompany(currentCompany) {    
    this.props.history.push("/home/bills/"+currentCompany.id);
  }

  getBillingCompanies = () => {
    var companiesData = dataManager.getAllDataByModel("Empresa");
    companiesData = companiesData.filter((currentCompany) => {
      return currentCompany.data.emiteFactura;
    })

    var amountImages = companiesData.length;
    var loadedImages = 0;

    return companiesData.map((currentCompany) => {
      return (<div onClick={() => {this.openCompany(currentCompany)}} key={currentCompany.id} className={this.props.classes.divImgContainer} style={{visibility:this.state.imgsLoaded?'visible':'hidden'}}>
        <img src={currentCompany.data.imagenSuperior} alt={currentCompany.id} className={this.props.classes.imgContainer} onLoad={() => {
          loadedImages++;
          if(loadedImages===amountImages) {
            this.setState({imgsLoaded:true})
          }
        }}/>
      </div>)
    })
  }

  render() {
    const { classes } = this.props;
    const { imgsLoaded } = this.state;

    var welcomed = dataManager.getWelcomed();

    return (
      <div className={classes.screenContainer}>
        {!welcomed && <Fragment>
          <img src="/back.jpg"/>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {dataManager.setWelcomed(true); this.forceUpdate()}}
          >
            CONTINUAR            
          </Button>     
        </Fragment>}
        {welcomed && <Fragment>
          <Typography variant="h4" component="h5" className={classes.title}>
            Seleccione la empresa que factura
          </Typography>
          <div className={classes.divAllImages}>
            {!imgsLoaded && <div className={classes.circularDiv}>
              <CircularProgress size={50} className={classes.circular} />
            </div>}
            {<Fragment>
              {this.getBillingCompanies()}            
            </Fragment>}
          </div>  
        </Fragment>}
        
      </div>      
    );
  }
}

BillingCompanies.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(BillingCompanies)));
