import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {Pie,Bar} from 'react-chartjs-2';
import 'chartjs-plugin-labels';


import MomentUtils from '@date-io/moment';

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import FacturacionClientes from '../data_loaders/informes/FacturacionClientes';
import FacturacionAcumuladaMesAnio from '../data_loaders/informes/FacturacionAcumuladaMesAnio';
import FacturacionMesAnio from '../data_loaders/informes/FacturacionMesAnio';
/*import Inventario from '../data_loaders/informes/Inventario';
import AlquilerPromedio from '../data_loaders/informes/AlquilerPromedio';
import Cardex from '../data_loaders/informes/Cardex';*/

import Tabla from '../screens/components/Tabla'

import dataManager from '../app/DataManager';

import { withContext } from '../app/AppContext';

const styles = theme => ({
  screenWindow: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  paperContainer: {
    margin: theme.spacing(2, 3, 2, 3),
    //padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },

  textFieldClass: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 'calc( 100% - ' + theme.spacing(4) +'px )',
    //flex: 1,
  },

  fullButton: {
    margin: theme.spacing(2),
    width: 'calc( 100% - ' + theme.spacing(4) +'px )',
  },

  fullWidth: {
    width: '100%',
  }
});

const informes = [
  {
    label: "Facturación acumulada por clientes",
    clase: FacturacionClientes,
  },
  {
    label: "Facturación acumulada por años o meses",
    clase: FacturacionAcumuladaMesAnio,
  },
  {
    label: "Facturación ordenada por años o meses",
    clase: FacturacionMesAnio,
  },
  
  
  /*{
    label: "Alquiler promedio",
    //clase: AlquilerPromedio,
  },*/
  /*{
    label: "Cardex",
    clase: Cardex,
  }*/
]

class Informes extends React.Component {

  state = {
    informe:'',
    //informe:informes[0],
    data:{}
  }

  async componentDidMount() {
    /*if(this.props.data===undefined) {
      this.context.showLoader(true,"Cargando "+this.props.model.tipoDato);
    }

    //console.log('Viene con parentReg',this.props.parentReg);

    if(this.props.appMenuOverride) {
      this.context.appMenuOverride(this.props.model.getMenuTitle(this.props.parentReg),this.onAppMenuBack)
    }*/
  }

  setInforme = (value) => {
    //console.log('Se hace set a ',value);

    var dataInforme = {};

    if(value.clase.getDefaultItem) {
      dataInforme = value.clase.getDefaultItem();
    }

    this.setState({informe:value,data:dataInforme});
  }

  getInformes = () => {
    const { classes } = this.props;

    return (<div key={"Informes"} className={classes.textFieldClass}>
      <Autocomplete
        key={"Informes"}
        onChange={(evt,val) => {this.setInforme(val)}}
        options={informes}
        getOptionLabel={(option) => {
          return option.label;
        }}
        renderInput={params => (
          <TextField {...params} label={"Tipo de estadistica"} variant="outlined" fullWidth error={false}/>
        )}
      />
    </div>)
  }

  setValue = (nombreDb,value) => {
    var newData = this.state.data;
    newData[nombreDb] = value;
    this.setState({data:newData},() => {
      this.forceUpdate();
    });
    
  }

  getSelectedInforme = (informe) => {

    if(!informe) return;

    const { classes } = this.props;

    var camposInforme = informe.clase;

    //console.log('state',this.state.data);

    return camposInforme.campos.map((value,index) => {

      //Si tiene una función que define si se muestra o no el campo
      //Ej, El botón de ver productos individuales para un TipoProducto solo se muestra
      //Si esta chequeado el check de Seguimiento individual.
      if(value.showField!==undefined) {
        if(!value.showField(this.state.data)) return;
      }

      if(value.tipo==="Select") {
        if(!this.state.data[value.nombreDb]) {
          var options = value.getOptions?value.getOptions():value.options;
          this.state.data[value.nombreDb] = options[0];
        }
      }

      if(value.tipo==="SelectorOtherTable") {
        var dataOtherTable = dataManager.getAllDataByModel(value.model.tipoDato);

        if(value.filtrarDatos) dataOtherTable = value.filtrarDatos(dataOtherTable);

        dataOtherTable = dataOtherTable.map((value) => {
          return {id:value.id,data:value.data};
        })

        if(value.habilitarTodos) {
          dataOtherTable.unshift({
            id:"Todos",
            data:{
              nombre: value.habilitarTodosLabel,
            }
          })

          if(this.state.data[value.nombreDb]===undefined) {
            this.state.data[value.nombreDb] = dataOtherTable[0];
          }
        }
        //console.log('La info de la otra tabla',dataOtherTable);
      }

      switch (value.tipo) {
        case 'SelectorOtherTable':
            return <div key={this.state.informe.label + value.nombre} className={classes.textFieldClass}>
              <Autocomplete
                key={value.nombre}
                value={this.state.data[value.nombreDb] || {}}
                onChange={(evt,val) => {this.setValue(value.nombreDb,val)}}
                options={dataOtherTable}
                getOptionLabel={(option) => {
                  return value.model.getInListNombre(option.data);
                }}
                renderInput={params => (
                  <TextField {...params} label={value.nombre} variant="outlined" fullWidth error={false}/>
                )}
              />
            </div>

        case 'Select':
            var options = value.getOptions?value.getOptions(this.state.data):value.options;
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Autocomplete
                key={value.nombre}
                value={this.state.data[value.nombreDb]||options[0]}
                onChange={(evt,val) => {this.setValue(value.nombreDb,val)}}
                options={options}
                renderInput={params => (
                  <TextField {...params} label={value.nombre} variant="outlined" fullWidth error={false}/>
                )}
              />
            </div>

        case 'Date':
            return <MuiPickersUtilsProvider key={value.nombre} utils={MomentUtils} locale={'es-us'}>
              <div className={classes.textFieldClass}>
                <Typography variant="subtitle1" className={classes.marginRight}>
                  {value.nombre}
                </Typography>
                <DatePicker
                  format="DD/MMM/YYYY"
                  id="date-picker-inline"
                  value={this.state.data[value.nombreDb]}
                  onChange={date => {this.setValue(value.nombreDb,date)}}
                  className={classes.fullWidth}
                  inputVariant="outlined"
                />
              </div>
            </MuiPickersUtilsProvider>

        case 'TextField':
            return <TextField
              autoFocus={index===0}
              key={value.nombre}
              id={"outlined-basic-"+value.nombre}
              className={classes.textFieldClass}
              label={value.nombre}
              margin="normal"
              variant="outlined"
              value={this.state.data[value.nombreDb]}
              onChange={event => {this.setValue(value.nombreDb,event.target.value)}}
              disabled={value.allowModify===false}
              type={value.onlyNumber?"number":""}
            />

        case 'Button':
            return <Button
              key={value.nombre}
              variant="contained"
              color="primary"
              className={classes.fullButton}
              startIcon={value.icon}
              onClick={async () => {
                var ret = await value.exec(this.state.data);
                if(ret?.error) {
                  this.context.showErrorMessageDialog(ret.error);
                }
                this.forceUpdate();
              }}
            >
              {value.nombre}
            </Button>

        case 'Tabla':
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Typography variant="subtitle1" className={classes.marginRight}>
                {value.nombre}
              </Typography>
              <Tabla key={value.nombre} data={value.processData(this.state.data)} />
            </div>
        
        case 'GraficoPastel': 
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Typography variant="subtitle1" className={classes.marginRight}>
                {value.nombre}
              </Typography>
              <Pie key={value.nombre} data={value.processData(this.state.data)} options={value.labelOption?value.labelOption():{}} />            
            </div>
        
        case 'GraficoBarras': 
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Typography variant="subtitle1" className={classes.marginRight}>
                {value.nombre}
              </Typography>
              <Bar key={value.nombre} data={value.processData(this.state.data)} options={value.labelOption?value.labelOption():{}} />            
            </div>
        
        case 'Label': 
            return <div key={value.nombre} className={classes.textFieldClass}>
              <Typography variant="h4" className={classes.marginRight}>
                {value.getLabel?value.getLabel(this.state.data):value.label}
              </Typography>              
            </div>



        default: break;
      }
    })
  }

  render() {
		const { classes,model } = this.props;
		const { itemInEdition,isCreation } = this.state;

    return (
      <div className={classes.screenWindow}>
        <React.Fragment>
          <Paper className={classes.paperContainer}>
            {this.getInformes()}
            {this.getSelectedInforme(this.state.informe)}
          </Paper>
        </React.Fragment>
      </div>
    )
  }
}

export default withStyles(styles,{ withTheme: true })(withContext(Informes));
