import * as React from 'react';

import empresaModel from '../models/Empresa';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { toCurrency, toPercentage,getRandomColors } from '../../helpers/NameUtils';

import dataManager from '../../app/DataManager';

import moment from 'moment-timezone';
import 'moment/locale/es';

var processAllDataAcumuladaMesAnio = async (data) => {
  if(!(data.empresa?.id!==undefined)) {
    return {error:"Seleccione la empresa para visualizar la estadística"}
  }
  else {
    var allFacturas = dataManager.getAllDataByModel("Factura");
    var companyBill = allFacturas.filter((currentBill) => currentBill.data?.billingCompanyId === data.empresa.id || data.empresa.id==="Todos")
    
    //console.log('data',data);

    var valuesBars;
    var labelBar;
    var tipoSelect;
    var totalPeriodo = 0;

    if(data.selectTiempo==="Estadistica por año") {           
      labelBar = "anuales";
      tipoSelect = "anual";
      valuesBars = companyBill.reduce((prev,currentBill)=> {
        const year = moment(currentBill.data.fecha.toDate()).format("YYYY");
        if(!prev[year])prev[year] = 0;
        prev[year]+=parseFloat(currentBill.data.valor);
        totalPeriodo+=parseFloat(currentBill.data.valor);        
        return prev;
      },{});
    }
    else {
      labelBar = "mensuales "+data.selectTiempo;
      tipoSelect = "mensual";
      valuesBars = companyBill.reduce((prev,currentBill)=> {
        const year = moment(currentBill.data.fecha.toDate()).format("YYYY");
        var month = moment(currentBill.data.fecha.toDate()).tz('America/Bogota').locale('es').format("MMMM");

        //console.log('month',month);

        if(year!==data.selectTiempo) return prev
        
        month = month[0].toUpperCase() + month.substr(1);
        prev[month]+=parseFloat(currentBill.data.valor);
        totalPeriodo+=parseFloat(currentBill.data.valor);
        return prev;
      },{"Enero":0,"Febrero":0,"Marzo":0,"Abril":0,"Mayo":0,"Junio":0,"Julio":0,"Agosto":0,"Septiembre":0,"Octubre":0,"Noviembre":0,"Diciembre":0});
    }

    //console.log('dataVentas',valuesBars);

    data.dataVentas = {labels:Object.keys(valuesBars),data:Object.values(valuesBars),labelBar,tipoSelect,totalPeriodo};
    //data.dataFacturacion = arrBilledData;
  }      
}

export default {
  tipoInforme:"FacturacionAcumuladaMesAnio",
  campos: [
    //{nombre:'Nota',nombreDb:'nota',tipo:'TextField'},
    {nombre:'Empresa Emisora',nombreDb:'empresa',tipo:'SelectorOtherTable',model:empresaModel
      ,filtrarDatos:(datos) => {
        datos = datos.filter(currentDato => currentDato.data.emiteFactura)        
        return datos;
      },habilitarTodos:true,habilitarTodosLabel:"Todas las empresas",
    },
    
    //{nombre:'Año o mes',nombreDb:'selectTiempo',tipo:'Select',options:["Estadistica por año","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]},
    {nombre:'Año o mes',nombreDb:'selectTiempo',tipo:'Select',getOptions:() => {
      var dataTiempo = ["Estadistica por año"];

      var allFacturas = dataManager.getAllDataByModel("Factura");
      var usedYears = allFacturas.reduce((prev,currentBill)=> {
        prev[moment(currentBill.data.fecha.toDate()).format("YYYY")] = true;
        return prev;
      },{})

      dataTiempo = dataTiempo.concat(Object.keys(usedYears));

      return dataTiempo;
    }},
    {nombre:'Visualizar',tipo:'Button',icon:<VisibilityIcon />,exec:(data) => {data.dataVentas = true}},

    /*{nombre:'Gráfica de ventas',nombreDb:'graficaVentas',tipo:'Tabla',
      showField:(data) => {return data.dataFacturacion!==undefined},
      processData:(data) => {        
        var columns = [{id:"nombre",label:"Nombre",align:"center"},
                       {id:"value",label:"Valor Facturado",align:"center",format:(value) => toCurrency(value)},
                       {id:"porc",label:"Porcentaje",align:"center",format:(value) => toPercentage(value)}];

        //console.log('data--->',data);
        return {columns,rows:data.dataFacturacion}
      }    
    },*/
    {nombre:'Ventas acumuladas',nombreDb:'acumulado',tipo:'Label',showField:(data) => {return data.dataVentas!==undefined},
      getLabel:(data) => {
        processAllDataAcumuladaMesAnio(data);
        var label = data.dataVentas.tipoSelect==="anual"?"generales":data.selectTiempo;
        return `Ventas acumuladas ${label}: ` + toCurrency(data.dataVentas.totalPeriodo);
      }
    },
    {nombre:'Gráfico ventas acumuladas',nombreDb:'graficoBarras',tipo:'GraficoBarras',
      showField:(data) => {return data.dataVentas!==undefined},
      processData:(data) => {
        var labels = data.dataVentas.labels;
        var values = data.dataVentas.data;        

        return {
          labels,
          datasets: [{
            label: 'Ventas '+data.dataVentas.labelBar,
            data: values,
            backgroundColor: "#6DA2CA",            
          }],          
        }
      },
      labelOption:() => {
        return {
          scales: {
              yAxes: [{
                  ticks: {
                      suggestedMin: 0,
                      suggestedMax: 10000
                  }
              }]
          },
          plugins: {
            labels: 
              {
                render: function (args) {
                  // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
                  return toCurrency(args.value);
                },              
              },  
          }
        }
      }
    }
  ],
}
