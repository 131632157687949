import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import TextField from '@material-ui/core/TextField';

import moment from 'moment-timezone';

import { withContext } from '../app/AppContext';

import { getPdfFromBill } from '../helpers/PdfDownloader';
//import { getFileName } from '../helpers/NameUtils';


/*import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
  Canvas,
} from '@react-pdf/renderer';*/

//import ReactPDF from '@react-pdf/renderer';

//import Pdf from "react-to-pdf";

/*import unnamed from "../res/template/bill_template.png";
import image from "../res/template/wm.png";
import image2 from "../res/template/wm2.png";
import image3 from "../res/template/wm3.png";*/

import * as jsPDF from 'jspdf'

const allImages = ["template/template.png","template/wm.png"]


const styles = theme => ({ 
  img: {
    width: '70%',
    position: 'absolute',
    left: '0',
  },
  iframe: {
    position: 'absolute',
    left: '0',
  }
});
//const PDFDocument = require('pdfkit');

// Create styles
/*const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});*/


const stylesMUI = theme => ({ 
});


/*Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});*/

const ref = React.createRef();
const canvasRef = React.createRef();
const iframeRef = React.createRef();

/*const styles = StyleSheet.create({
  body: {    
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
    transform:"translateY(60)",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    //width: '1000px',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});*/

class PdfRender extends Component {

  state = {data:""}

  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = function (e) {
        // Add the file name to the data URL
        let dataURL = e.target.result;

        //console.log('Lee',dataURL);
        dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
        resolve({file, dataURL});
      };

      reader.readAsDataURL(file);
    });
  }

  getImageData(file) {
    return new Promise ((resolved) => {
      var i = new Image();
      
      i.onload = (evt) => {
        //console.log('Termina',file);
        resolved([file,i]);
      }
      //console.log('Pone',file);
      i.setAttribute('crossOrigin', 'anonymous')
      i.crossOrigin="anonymous";
      i.src = file;
    })
  }

  // function to encode file data to base64 encoded string
  /*base64_encode(file) {
    // read binary data
    var bitmap = fs.readFileSync(file);
    // convert binary data to base64 encoded string
    return new Buffer(bitmap).toString('base64');
  }*/

  getData = (data,noData) => {
    return data?data:noData;
  }

  loadAllImages = async () => {
    /*var allPromises = [];

    allImages.push(this.props.data.billingCompanyId.data.imagenSuperior);
    allImages.push(this.props.data.billingCompanyId.data.imagenMarcaAgua);
    
    allImages.forEach(imgPath => {
      allPromises.push(this.getImageData(imgPath));
    })

    //console.log('Promises',allPromises);
    Promise.all(allPromises).then(allImagesData => {
      allImagesData = allImagesData.reduce((prev,current) => {
        //console.log('prev',prev);
        prev[current[0]] = current[1];
        return prev;
      },{});
      
      //console.log('allImagesData2',allImagesData);

      var doc = new jsPDF({format: 'letter'});      
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();


      //console.log(width,'X',height);

      //doc.addImage(allImagesData["template/bill_template.png"], "PNG", 15, 40, 180, 180);
      doc.addImage(allImagesData[this.props.data.billingCompanyId.data.imagenMarcaAgua], "PNG", 17, 152, 185,77);
      //doc.addImage(allImagesData["template/wm.png"], "PNG", 17, 152, 185,77);
      doc.addImage(allImagesData["template/template.png"], "PNG", 0, 0, width, height);
      doc.setFontSize(13);
      //doc.text("" + this.props.data.consecutivo, 0, 0);

      //console.log('this.props.data.fecha',this.props.data.fecha);
      doc.text("" + this.getData(this.props.data.consecutivo||"[CONSECUTIVO]"), 186, 37,{baseline:"middle",align:"center"});
      doc.text(moment(this.props.data.fecha).format('DD/MM/YYYY'), 155, 37,{baseline:"middle",align:"center"});
      doc.addImage(allImagesData[this.props.data.billingCompanyId.data.imagenSuperior], "PNG", 56-(82/2), 23-(20/2), 82, 20);
      doc.text("" + this.getData(this.props.data.billingCompanyId.data.direccion||"[DIRECCION]"), 16, 43,{baseline:"bottom",align:"left"});
      doc.text(this.getData(this.props.data.billingCompanyId.data.ciudad||"[CIUDAD]") + ", " + this.getData(this.props.data.billingCompanyId.data.ampliacionDireccion||"[AMPLIACION DIRECCION]") + ".", 16, 49,{baseline:"bottom",align:"left"});
      doc.text("" + this.getData(this.props.data.billingCompanyId.data.pais||"[PAIS]"), 16, 55,{baseline:"bottom",align:"left"});

      var currentY = 88;
      doc.text("" + this.getData(this.props.data.billedCompany.data.nombre), 25, 88,{baseline:"bottom",align:"left"});
      
      if(this.props.data.billedCompany.data.direccion) {
        currentY+=6;
        doc.text("" + this.getData(this.props.data.billedCompany.data.direccion), 25, currentY,{baseline:"bottom",align:"left"});
      }

      if(this.props.data.billedCompany.data.ciudad || this.props.data.billedCompany.data.ampliacionDireccion) {
        currentY+=6;        
        doc.text(this.getData(this.props.data.billedCompany.data.ciudad) + ", " + this.getData(this.props.data.billedCompany.data.ampliacionDireccion) + ".", 25, currentY,{baseline:"bottom",align:"left"});
      }

      if(this.props.data.billedCompany.data.telefono) {
        currentY+=6;        
        doc.text(this.getData(this.props.data.billedCompany.data.telefono), 25, currentY,{baseline:"bottom",align:"left"});
      }

      if(this.props.data.billedCompany.data.pais) {
        currentY+=6;        
        doc.text(this.getData(this.props.data.billedCompany.data.pais), 25, currentY,{baseline:"bottom",align:"left"});
      }

      /*if(this.props.data.billedCompany.data.pais) {
        currentY+=6;        
        doc.text(this.getData(this.props.data.billedCompany.data.pais), 25, currentY,{baseline:"bottom",align:"left"});
      }

      var printConcept = (str,initY) => {

        var lines = doc.splitTextToSize(str, 70);
        doc.text(lines, 56, initY,{baseline:"middle",align:"left"});

        //doc.text(str, 56, initY,{baseline:"middle",align:"left"});
        return initY + ((lines.length-1)*6);
      }

      if(this.props.data.conceptos) {

        var conceptos = this.props.data.conceptos;

        var currentY = 160;

        var initDate = moment(this.props.data.fechaIniPeriodo).format('DD MMMM');
        var endDate = moment(this.props.data.fechaFinPeriodo).format('DD MMMM');

        var total = parseFloat(this.props.data.valor);
        var remaining = total;

        var currencyFormat = new Intl.NumberFormat("en-US",
                        { style: "currency", currency: "USD",
                          minimumFractionDigits: 2 });

        for (var indexConcepto = 0; indexConcepto < conceptos.length; indexConcepto++) {
          const currentConcepto = conceptos[indexConcepto];          
          doc.text("01", 34, currentY,{baseline:"middle",align:"center"});
          doc.text("0%", 187, currentY,{baseline:"middle",align:"center"});

          var conceptValue = Math.round(total*parseFloat(currentConcepto.porcentaje))/100;
          if(indexConcepto===conceptos.length-1) {
            conceptValue = remaining;
          } else {
            remaining-=conceptValue;
          }

          doc.text(currencyFormat.format(conceptValue), 149, currentY,{baseline:"middle",align:"center"});
          currentY = printConcept(initDate + " - " + endDate + " - " + currentConcepto.data.data.concepto,currentY)
          //currentY = printConcept("Este es un concepto muy largo largo, Este es un concepto muy largo largo  Este es un concepto muy largo largo  Este es un concepto muy largo largo",currentY)
          
          currentY+=10;
        }

        doc.text(currencyFormat.format(total), 187, 237,{baseline:"middle",align:"center"});
      }

      if(this.props.data.billedCompany.data.web) {
        doc.setTextColor("#FFFFFF")
        doc.text(this.getData(this.props.data.billedCompany.data.web), 109, 272,{baseline:"middle",align:"center"});
      }

      //doc.text("" + this.props.data.consecutivo, 35, 25);*/
      
      var doc = await getPdfFromBill(this.props.data);
      
      ////console.log('blob',doc.output('datauristring'));
      this.blob2canvas('',doc.output('blob'))

      ////console.log('canvas',document.getElementById("bill_canvas").getContext()); 
      //var context = canvasRef.current.getContext("2d");
      //context.drawImage(doc.output('datauri'), 0, 0);
      ////console.log('canvas',context);
      //console.log('canvas',doc.canvas);

      //console.log('iframe',iframeRef);
      //iframeRef.current.src = doc.output('datauristring');

      iframeRef.current.src = doc.output('bloburl');
      //document.getElementById("pdf_preview")

      
      //var imageData = doc.canvas.getContext("2d").getImageData(0,0,100,100);
      //canvasRef.current.getContext("2d").putImageData(imageData, 0, 0);
      ////console.log('canvas',canvasRef.current.setContext);
      
      ////console.log('datauri',doc.output('datauri'));
      ////console.log(doc.canvas)
      //doc.save('two-by-four.pdf');
    //})
  }

  blob2canvas(canvas,blob){
    var img = new Image();
    //var ctx = canvas.getContext('2d');
    img.onload = function () {
      //console.log('img',img);
      //ctx.drawImage(img,0,0);
    }

    //console.log('CARGA BLOB',blob);
    img.src = blob;
  }

  componentDidMount() {

    this.loadAllImages();

    //this.getImageData(image2);
    //this.base64_encode(image2);

    /*var doc = new jsPDF();

    doc.setFontSize(40);
    doc.text("Octonyan loves jsPDF", 35, 25);
    //console.log('Imagen desde0',image);
    //console.log('Imagen desde',unnamed);
    doc.addImage(image, "PNG", 15, 40, 180, 180);
    doc.addImage(unnamed, "PNG", 15, 40, 180, 180);
    //doc.addImage("res/template/wm.png", "PNG", 15, 40, 180, 180);
    //doc.addImage(__dirname+"/template/wm.png", "PNG", 15, 40, 180, 180);
    doc.save('two-by-four.pdf');*/

    ////console.log('Tioene el objeto',PDFDocument);
    ////console.log(ReactPDF.PDFRenderer);
    //ReactPDF.render(<Quixote />, `${__dirname}/example.pdf`);
  }

  /*getQuixote = () => (
    <Document>
      <Page size="LETTER" style={styles.body}>
        <Image
          style={styles.image}          
          src= {"template/bill_template.png"}
          fixed
        />        
        <Image
          style={styles.image}          
          src= {"template/wm.png"}
          fixed
        />   
        {<Canvas paint={painter => painter.path('M0,0L0,300L300,300z')} />}
        <Text style={styles.header} fixed>
          {this.state.data}
        </Text>
      </Page>
    </Document>
  );*/

 /*render() {
  return (<React.Fragment>
      <PDFViewer style={{width:700}}>
          {this.getQuixote()}
      </PDFViewer>
      <PDFDownloadLink document={this.getQuixote()} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (
          <button>
            Download now!
          </button>
          )}
      </PDFDownloadLink>
      <TextField
        label={"Dato"}
        margin="normal"
        variant="outlined"
        value={this.state.data}
        onChange={event => {this.setState({data:event.target.value})}}              
      />
    </React.Fragment>)
 }*/

 render() {
    const { classes } = this.props;

    return (<div className="App">
      <iframe id="pdf_preview" className={classes.iframe} width='100%' height='100%' ref={iframeRef}></iframe>      
    </div>)
 }
}

PdfRender.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(withContext(PdfRender)));
