import dataManager from '../app/DataManager';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

class ModelsLoader {

  init(config) {

    config = Object.assign({},{noUpdateSnapshot:false,arrDBS:[]},config);

    this.onDbDataChangeListeners = {};    
    this.dbData = {};
    
    config.arrDBS.forEach(currentDb => {
      this.dbData[currentDb] = []
      this.loadDB(currentDb,config.noUpdateSnapshot);    
    });      
  }

  setListener = (dbName,onDbDataChange) => {
    //console.log('Coloca listener para',dbName);
    this.onDbDataChangeListeners[dbName] = onDbDataChange;
  }

  onDataChange = (dataName,newData) => {
    ////console.log('Entra',dataName,this.onDbDataChangeListeners[dataName]);
    this.dbData[dataName] = newData;

    //Si hay un listener para el cambio de esta BD entonces lo llama
    if(this.onDbDataChangeListeners[dataName]!==undefined) {
      this.onDbDataChangeListeners[dataName](dataName,newData);
    }
  }

  async loadDB(dataName,noUpdateSnapshot) {
    //Tener en cuenta que readAndListenCollection llama de por si la funcion onDataChange con el primer snapshot de datos
    await dataManager.readAndListenCollection(dataName,this.onDataChange,noUpdateSnapshot);
    //return await dataManager.getAllSnapshotsByCollection(dataName);
  }
}

//export let modelsLoader = new ModelsLoader();
////console.log('Puedo imprimir y tengo', modelsLoader);


const modelsLoader = new ModelsLoader();
export default modelsLoader;
