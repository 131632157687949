import * as React from 'react';

import empresaModel from '../models/Empresa';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { toCurrency } from '../../helpers/NameUtils';

import dataManager from '../../app/DataManager';

import moment from 'moment-timezone';
import 'moment/locale/es';


var processAllData = async (data) => {
  if(!(data.empresa?.id!==undefined)) {
    return {error:"Seleccione la empresa para visualizar la estadística"}
  }
  else {
    var allFacturas = dataManager.getAllDataByModel("Factura");
    var allEmpresas = dataManager.getAllDataByModel("Empresa");
    var companyBill = allFacturas.filter((currentBill) => currentBill.data?.billingCompanyId === data.empresa.id || data.empresa.id==="Todos")
    
    //console.log(companyBill);

    var dataFacturacion = companyBill.reduce((prev,currentBill)=> {

      if(!currentBill.data.billedCompany) return prev;

      const year = moment(currentBill.data.fecha.toDate()).format("YYYY");
      var month = moment(currentBill.data.fecha.toDate()).format("MMMM");
      month = month[0].toUpperCase() + month.substr(1);

      if((year===data.selectYear || data.selectYear==="Cualquier año") 
          && (month===data.selectMonth || data.selectMonth==="Cualquier mes")
        ) {
          //console.log('currentBill',currentBill,allEmpresas,allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBill.data.billedCompany.id));
          //console.log('currentBill',currentBill);
          prev.push({
            fecha: moment(currentBill.data.fecha.toDate()).format('DD/MMMM/YYYY'),
            emisora: allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBill.data.billingCompanyId).data.nombre,
            nombre: allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBill.data.billedCompany.id).data.nombre,
            valor: parseFloat(currentBill.data.valor)
          })
      }
      //console.log('Y-M',year,month);
      
      /*if(!prev[year])prev[year] = 0;
      prev[year]+=parseFloat(currentBill.data.valor);*/
      return prev;
    },[]);

    dataFacturacion = dataFacturacion.sort((a,b)=> b.valor - a.valor);

    data.dataFacturacion = dataFacturacion;
  }      
}

export default {
  tipoInforme:"FacturacionMesAnio",
  campos: [
    //{nombre:'Nota',nombreDb:'nota',tipo:'TextField'},
    {nombre:'Empresa Emisora',nombreDb:'empresa',tipo:'SelectorOtherTable',model:empresaModel
      ,filtrarDatos:(datos) => {
        datos = datos.filter(currentDato => currentDato.data.emiteFactura)        
        return datos;
      },habilitarTodos:true,habilitarTodosLabel:"Todas las empresas",
    },
    
    //{nombre:'Año o mes',nombreDb:'selectTiempo',tipo:'Select',options:["Estadistica por año","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"]},
    {nombre:'Año',nombreDb:'selectYear',tipo:'Select',
      showField:(data) => {return data.empresa!==undefined},
      getOptions:(data) => {
        if(!data) return ["Cualquier año"];
        var dataTiempo = ["Cualquier año"];

        var allFacturas = dataManager.getAllDataByModel("Factura");
        var usedYears = allFacturas.reduce((prev,currentBill)=> {
          if(currentBill.data.billingCompanyId!==data.empresa?.id) return prev;
          prev[moment(currentBill.data.fecha.toDate()).format("YYYY")] = true;
          return prev;
        },{})

        dataTiempo = dataTiempo.concat(Object.keys(usedYears));

        return dataTiempo;
      }
    },
    {nombre:'Mes',nombreDb:'selectMonth',tipo:'Select',
      showField:(data) => {return data.empresa!==undefined},
      getOptions:() => {
        return ["Cualquier mes","Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
      }
    },
    {nombre:'Visualizar',tipo:'Button',icon:<VisibilityIcon />,exec:(data) => {data.dataFacturacion = true}
    },

    {nombre:'Tabla de ventas',nombreDb:'graficaVentas',tipo:'Tabla',
      showField:(data) => {return data.dataFacturacion!==undefined},
      processData:(data) => {
        processAllData(data);
        var columns = [{id:"fecha",label:"Fecha",align:"center"}]        
        if(data.empresa.id==="Todos") columns.push({id:"emisora",label:"Emisora",align:"center"});
        columns.push({id:"nombre",label:"Cliente",align:"center"});
        columns.push({id:"valor",label:"Valor Facturado",align:"center",format:(value) => toCurrency(value)});
                       
        //console.log('data--->',data);
        return {columns,rows:data.dataFacturacion}
      }    
    },  
  ],
}
