import * as React from 'react';
import dataManager from '../../app/DataManager';
const {getFileName,uniqueId} = require('../../helpers/NameUtils');

export default {
  tipoDato:"Concepto",  
  campos: [
    {nombre:'Concepto',nombreDb:'concepto',tipo:'TextField'},    
  ],
  getDefaultItem: (arr_data) => {
    return {
      'nombre': 'Concepto',      
    }
  },
  getNombre: (data) => {return data.concepto},
  generateId: (data) => {
    return getFileName(data.concepto)+uniqueId();
  },
  //Cuando se listan todos los datos de este tipo en un select, como debe aparecer
  getInListNombre: (data) => {
    return data.concepto;
  },
  /**
   * infoItem -> {id,data,ref}
   */
  delete:async (infoItem) => {

    var allFacturas = dataManager.getAllDataByModel("Factura");
    var allEmpresas = dataManager.getAllDataByModel("Empresa");

    var conceptUsed = [];
  
    allFacturas.forEach(currentBill => {

      var conceptos = currentBill.data.conceptos;

      conceptos.forEach(currentConcepto => {
        if(currentConcepto.data.id === infoItem.id) {
          var companyName = allEmpresas.find(currentEmpresa => currentEmpresa.id === currentBill.data.billingCompanyId).data.nombre;
          conceptUsed.push("De " + companyName + " (consecutivo: " + currentBill.data.consecutivo + ")");
        }
      })      
    })

    if(conceptUsed.length>0) {
      conceptUsed.unshift("No se puede eliminar porque ya existen una o mas facturas con este concepto:");
      conceptUsed = conceptUsed.map((val,index) => <p key={index}>{val}</p>);
      return {error:true,msg:conceptUsed}
    }  

    await dataManager.removeDataFromFirestoreByRef(infoItem.ref,'Concepto');
    return {success:true}
  }
}
