import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { withContext } from '../../app/AppContext';
import EditorDatos from './EditorDatos';
import dataManager from '../../app/DataManager';

const {uniqueId} = require('../../helpers/NameUtils');
const {goToParent} = require('../../helpers/UrlUtils');

const styles = theme => ({
  screenWindow: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  filtroContainer: {
    margin: theme.spacing(2,3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paperContainer: {
    margin: theme.spacing(0, 3, 2, 3),
    //padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: '85%',
  },

  inputFiltro: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
    //width: 200,
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  list: {
    padding: 0,
    height: '85%',
    overflowY: 'auto',
  },

  listItemSecondaryAction: {
    visibility: "hidden"
  },

  listItem: {
    padding: theme.spacing(2,1),
  },

  listItemContainer: {
    //borderBottom: "1px solid darkgray",
    boxShadow: "inset 0 -1px 0 0 rgba(100,121,143,0.122)",
    backgroundColor: 'white',
    "&:hover": {
      boxShadow: "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
      zIndex: 1,
    },
    "&:hover $listItemSecondaryAction": {
      visibility: "inherit"
    }
  },

  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(5),
  },
});

class GestorDatos extends React.Component {

  state = {
    filtro:'',
    itemInEdition:null,
  }

  async componentDidMount() {
    if(this.props.data===undefined) {
      //this.context.showLoader(true,"Cargando "+this.props.model.tipoDato);
    }

    //console.log('Viene con parentReg',this.props.parentReg);
    //console.log('props',this.props);

    if(this.props.appMenuOverride) {
      this.context.appMenuOverride(this.props.model.getMenuTitle(this.props.parentReg),this.onAppMenuBack)
    }
  }

  componentWillUnmount() {
    //console.log('Pide que desmonte el gestor',);
  }

  tryRestoreAppMenuBack = () => {
    if(this.props.appMenuOverride) {
      this.context.appMenuOverride(this.props.model.getMenuTitle(this.props.parentReg),this.onAppMenuBack)
    }
  }

  onAppMenuBack = () => {
    this.context.removeAppMenuOverride();
    this.props.parentEditor.closeSubPanel();
  }

  clickCreateNew = () => {
    //console.log('CLIC CREAR CON PROPS',this.props);
    this.props.history.push(this.props.location.pathname+"/create")
    /*var infoItem = {id:'newData'+uniqueId(),data:this.props.model.getDefaultItem(this.props.data)};
    //console.log('Queda New',infoItem);
    this.setState({itemInEdition:infoItem,isCreation:true});*/
  }

  /**
   * infoItem -> {id,data,ref}
   */
  editItem = async (infoItem) => {
    //console.log('Clic con ',infoItem);
    //console.log("Props es",this.props);    

    this.props.history.push(this.props.location.pathname+"/"+infoItem.id)

    /*this.context.showLoader(true,"Cargando...");

    //Si tiene algún procesamiento al cargar, por ejemplo las remisiones que deben organizar su informaci
    if(this.props.model.onLoad) {
      this.props.model.onLoad(infoItem);
    }

    dataManager.fillData(infoItem,this.props.model,(infoItem) => {
      //console.log('Queda',infoItem);
      this.setState({itemInEdition:infoItem});
      this.context.showLoader(false);
    });*/
  }

  /**
   * infoItem -> {id,data,ref}
   */
  deleteItem = async (infoItem) => {
    //console.log('Clic Borrar con ',infoItem);

    if(this.props.model.delete) {
      this.context.showDialog({title:"Confirmación",message:"¿Estas seguro de borrar este dato, no se puede deshacer?",buttons:[
        {label:"Si",callback:async () => {
          this.context.showLoader(true,"Eliminando...");
          //Si el dato a borrar empieza por newData entonces no hay que validar nada, solo borrarlo del arreglo del padre
          if(infoItem.id.startsWith("newData")) {
            this.props.fnBorrar(infoItem);
            //Si no tiene referencia era un item no guardado
            if(!infoItem.ref) {
              this.context.showDialog({title:"Confirmación",message:"Elemento borrado exitosamente"})
              return;
            }
          }

          var deleteData = await this.props.model.delete(infoItem);
          if(deleteData.error) {
            this.context.showDialog({title:"Error",message:deleteData.msg})
          } else {
            //Si es un subcoleccion entonces lo borra manualmente ya que si fuera una coleccion completa
            //la actualizacion realtime de Firestore lo borra desde los props
            if(this.props.subCollection) {
              this.props.fnBorrar(infoItem);
            }

            this.context.showDialog({title:"Confirmación",message:"Elemento borrado exitosamente"})
          }
        }},
        {label:"No"}
      ]});
    }
  }

  getDatosComponents = () => {
    const { classes,data } = this.props;

    ////console.log('Imprime con ',data);

    if(data===undefined) return;
    
    var sortedData = data;

    if(this.props.model.sortData) {
      sortedData = this.props.model.sortData(data);
    }

    //return this.state.obj.map((value,index) => {
    return sortedData.map((value,index) => {

      var currentData = value.data;


      ////console.log('MOSTRANDO DATA',currentData);

      if(this.state.filtro!=='' && !this.props.model.getNombre(currentData).toLowerCase().includes(this.state.filtro.toLowerCase())) return;

      //Si hay un prefiltro (por ejemplo estoy mostrando las obras pero de determinado cliente)
      /*if(this.props.prefilter) {
        if(currentData[this.props.prefilter.idMatchChild]!==this.props.prefilter.parent.id) return;
      }*/

      return <ListItem
        key={index}
        dense
        button
        onClick={() => {this.editItem(value)}}
        className={classes.listItem}
        classes={{container: classes.listItemContainer}}
      >
        <ListItemText primary={this.props.model.getNombre(currentData)}
          secondary={this.props.model.getNombreSub?this.props.model.getNombreSub(currentData):""}
          secondaryTypographyProps={{color:'error'}}
        />
        <ListItemSecondaryAction
          className={classes.listItemSecondaryAction}
        >
          {
            this.props.model.listAdditionalButtons && this.props.model.listAdditionalButtons.map((additionalButton,index) => <IconButton key={index} className={classes.icon} edge="end" aria-label="comments" onClick={async () => {await additionalButton.callback(value,this)}}>
              {additionalButton.icon}
            </IconButton>)
          }
          <IconButton className={classes.icon} edge="end" aria-label="comments" onClick={() => {this.editItem(value)}}>
            <EditIcon />
          </IconButton>
          <IconButton className={classes.icon} edge="end" aria-label="comments" onClick={() => {this.deleteItem(value)}}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    })
  }

  closeEdition = () => {
    this.context.appMenuBackOverride();
    //console.log('this.props',this.props);
    goToParent(this.props);

    this.setState({isCreation:false});
    this.context.showLoader(false);
  }

  fnBorrar = async (key,info) => {
    //console.log('Recibe',key);
    //console.log('Recibe',info);
    //Si tiene una subcoleccion es porque es un item interno (por ejemplo estoy editando la lista de obras de un cliente)
    if(this.props.subCollection) {
      this.props.fnBorrar(key,info);
    }
  }

  fnGuardar = async (key,info) => {

    //console.log('Manager Save Prev',key,info,this.props);

    if(this.props.additionalData) {
      Object.assign(info.data,this.props.additionalData)
    }

    //Si el modelo tiene una función para transformar los datos antes de guardarlos entonces
    //los transforma
    if(this.props.model.transformDataForSave) {
      this.context.showLoader(true,"Guardando...");
      var infoTemp = await this.props.model.transformDataForSave(info,this.state.itemInEdition);
      //console.log('Sale con',infoTemp);
      if(infoTemp) {
        info = infoTemp;
      }

      if(info.error) {
        //this.closeEdition();
        this.context.showErrorMessageDialog(info.error);
        return;
      }
    }

    //console.log('Hasta acá',info);

    //Si tiene una subcoleccion es porque es un item interno (por ejemplo estoy editando la lista de obras de un cliente)
    if(this.props.subCollection) {
      this.closeEdition();
      this.props.fnGuardar(key,info);
    }
    //Si no tiene es porque es de los datos de primer nivel y ahi si debe guardar de verdad en DB
    else {
      //console.log('Que haga close',);
      this.closeEdition();
      //console.log('Manager Save',key,info,this.props.data);
      this.context.showLoader(true,"Guardando...");
      //Por ejemplo la función de transformDataForSave de remision, guarda la remision como tal, por lo que
      //no se hace este guardado de nuevo
      if(!info.preventDefault) {
        dataManager.saveData(this.props.model,info,() => {
          this.context.showLoader(false);
        })
      }
    }
  }

  render() {

    if(this.props.data===undefined) {
      return null;
    }

    var itemInEdition;

    if(this.props.match.params.id!==undefined) {
      if(this.props.match.params.id==="create") {
        itemInEdition = {id:'newData'+uniqueId(),data:this.props.model.getDefaultItem(this.props.data)};        
      }
      else {
        itemInEdition = this.props.data.find(value => value.id===this.props.match.params.id);   
      }
    }

    //console.log('state de gestor',this.state,this.props,this.context);

		const { classes,model } = this.props;
		const { isCreation } = this.state;

    return (
      <div className={classes.screenWindow}>
        {!itemInEdition && <React.Fragment>
          <div className={classes.filtroContainer}>
            <TextField
              id="outlined-basic"
              className={classes.inputFiltro}
              label="Filtro"
              margin="normal"
              variant="outlined"
              value={this.state.filtro}
              onChange={event => {this.setState({filtro:event.target.value})}}
            />
          </div>
          <Paper className={classes.paperContainer}>
            <List className={classes.list}>
              {this.getDatosComponents()}
            </List>
            <Fab aria-label={"Add"} className={classes.fab} color="primary" onClick={this.clickCreateNew} >
              <AddIcon />
            </Fab>
          </Paper>
        </React.Fragment>}
        {itemInEdition && <EditorDatos reg={itemInEdition}
              model={model}
              closeEdition={this.closeEdition}
              gestorDatos={this}
              fnGuardar={this.fnGuardar} //Este se pasa si es un sub item, si es directo de App.js no lo tendria
              subCollection={this.props.subCollection} //Este se pasa si es un sub item, si es directo de App.js no lo tendria
              isCreation={isCreation}
              fnBorrar={this.fnBorrar}
              additionalData={this.props.additionalData}
        />}
      </div>
    )
  }
}

export default withStyles(styles,{ withTheme: true })(withContext(GestorDatos));
