import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';

import ItemTipoProducto from './ItemConcepto';

import dataManager from '../../app/DataManager';

import { withContext } from '../../app/AppContext';

import { getTotal } from '../../data_loaders/models/Factura';

const styles = theme => ({
  total : {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
});

class SelectorConceptos extends React.Component {

  state = {
    conceptos:[],
    data:[],
  }

  constructor(props) {
    super(props)

    //console.log('Construye Selector con',props.data,this);

    this.cantidadesEnEstaRemision = {};

    this.countItems = 0;

    if(props.data!==undefined) {
      props.data.forEach((val) => {
        if(val.data && val.data.id) {
          this.countItems++;          
        }
      })

      this.state.data = props.data;
      this.isEdition = true;
    }    
  }

  componentDidMount() {
    this.getConceptos();
  }

  getPorcRandomData = (amountConcepts) => {
    var allPorc = [];

    var porc = 100;
    
    //for (var currentPorcIndex = amountConcepts; currentPorcIndex > 1; currentPorcIndex--) {        
    while(allPorc.length<amountConcepts) {
      if(allPorc.length===amountConcepts-1) {
        allPorc.push(porc);
      }
      else {
        var currentPorc = (Math.floor(Math.random()*20)+1)*5;
        if(porc*0.7>currentPorc) {
          allPorc.push(currentPorc);
          porc-=currentPorc;
        }
      }
    }
    
    ////this.console.log(allPorc," => ",allPorc.reduce((prev,curr) => prev+curr,0));
    return allPorc;
  }

  getConceptos = async() => {
    var data = await dataManager.getAllDataByModel('Concepto');
    //console.log('Se trae a',data);

    var dataSeleccionadaActual = this.state.data;

    if(!this.props.data) {
      var amountConcepts = Math.floor(Math.random()*3)+1;

      var usedConcepts = {};

      var allPorc = this.getPorcRandomData(amountConcepts);

      for (var indexConcept = 0; indexConcept < amountConcepts; indexConcept++) {        

        var randomConcept = Math.floor(Math.random()*data.length);
        
        if(usedConcepts[randomConcept]!==undefined) {
          indexConcept--;
          continue;
        }

        usedConcepts[randomConcept] = true;

        var dataConcept = data[randomConcept];
        delete dataConcept.ref;

        if(allPorc[indexConcept]!==0) {
          dataSeleccionadaActual.push({
            id: indexConcept,
            data: dataConcept,
            porcentaje: allPorc[indexConcept]
          })
        }
      }
    }

    //console.log('Deja seleccionadaActual a',dataSeleccionadaActual);

    /*data = data.map((value) => {
      return {id:value.id,data:value.data};
    });*/
    //if(this.isEdition!==true) this.addItem();
    this.countItems = dataSeleccionadaActual.length;

    this.setState({conceptos:data,data:dataSeleccionadaActual},() => {
      if(!this.props.data) {
        this.props.onChange(this.state.data);
      }
    })
  }

  setItemIndividualData = (idItem,idItemIndividual,val) => {
    var data = this.state.data;

    //Cambia el data seleccionado en esta posición
    var dataAntes = data[idItem].itemsIndividuales[idItemIndividual];
    dataAntes.id = idItemIndividual;
    dataAntes.data = val;

    data[idItem].itemsIndividuales[idItemIndividual] = dataAntes;
    this.setState({data:this.state.data});
    this.props.onChange(this.state.data);
  }


  setItemData = (id,data) => {
    //console.log("Va a buscar con id",id,"data",data);

    for (let currentItem = 0; currentItem < this.state.data.length; currentItem++) {
      const element = this.state.data[currentItem];

      if(element.id===id) {
        //console.log("encuentra a",element);
        //Si esta seteando el elemento
        if(data.porcentaje===undefined) {
          Object.assign(element.data,data);
          //console.log("lo deja",this.state.data[currentItem]);
        }
        //Si esta seteando la porcentaje del elemento
        else {
          element.porcentaje=data.porcentaje;
          //Mira si ya tenia seteado el elemento anteriormente
          if(element.data.id) {
            //Si si lo tenia mira si es un elemento individual
            if(element.data.data.alquilerIndividual) {
              //Si si lo es entonces crea el array de items individuales si no existe
              if(element.itemsIndividuales===undefined) {
                element.itemsIndividuales = this.createIndividualArray(null,data.porcentaje);
              }
              else {
                //Si la nueva porcentaje es mayor que la porcentaje anterior entonces agrega las posiciones necesarias
                if(data.porcentaje>element.itemsIndividuales.length) {
                  element.itemsIndividuales = this.createIndividualArray(element.itemsIndividuales,data.porcentaje);
                }
                //No se hace lo inverso (que borre posiciones si es menor) ya que por usabilidad si por ejemplo yo establezco
                //14 items y los selecciono, y luego para pasar a 15 borro el 5 del input antes de copiar el 4, entonces por un momento
                //el numero seria un 1 en el input y si borro las posiciones entonces toda la info seleccionada se pierde, es por esto
                //que todo se deja y simplemente en el componente no se pinta la informacion mayor que la cantidad y tampoco se guarda
              }
            }
          }
        }

        this.props.onChange(this.state.data);
        break;
      }
    }

    //console.log('Deja data con',this.state.data);
    this.setState({data:this.state.data});
  }

  createIndividualArray = (arrayOriginal, cantidad) => {
    var arrayRet = [];

    if(arrayOriginal===null || arrayOriginal===undefined) {
      for (var i=0; i < cantidad; i++) {
        arrayRet.push({id:i,data:{}});
      }
    }
    else {
      arrayRet = arrayOriginal;
      while(arrayRet.length<cantidad) {
        arrayRet.push({id:arrayRet.length,data:{}});
      }
    }

    return arrayRet;
  }

  addItem = (dataTipoProducto = {},cantidad=1) => {
    //console.log('this aca',this);

    if(this.countItems<5) {
      var data = this.state.data;
      data.push({id:this.countItems,data:dataTipoProducto,cantidad});
      this.countItems++;
      this.setState({data});
    }
    else {
      this.context.showErrorMessageDialog("No pueden tener mas de 5 conceptos en la misma factura.");
    }
  }

  borrarItem = (index) => {
    var items = this.state.data;
    items.splice(index,1);
    this.setState({items});
    this.props.onChange(this.state.data);
  }

  filtrarDatos = (seleccionado) => {
    ////console.log('Filtrar con seleccionado',seleccionado,this.state.data);

    var idConceptosUsados = this.state.data.map((value) => {
      return value.data.id;
    }).filter(value => value!==undefined);

    var data = this.state.conceptos;
    ////console.log('pref',idConceptosUsados,"--",data);

    data = data.filter((value) => {
      var include = idConceptosUsados.includes(value.id);
      ////console.log('Verificanto',idConceptosUsados,value,include);
      //Si si esta entre los usados mira que no sea que el itemTipoProducto que llama esta función sea el que
      //lo este usando
      if(include) {
        ////console.log('Verificando int',value.id,seleccionado.data.id);
        return value.id===seleccionado.data.id;
      }

      return true;
    });

    data = data.map((value) => {
      return {id:value.id,data:value.data};
    });

    ////console.log('data',data);
    return data;
  }

  /*
  Supongamos que yo tengo en total total 10 canguros y por ejemplo en una remision utilizo 2, al guardar esa remision, el sistema
  descuenta esos 2 canguros pues estan comprometidos, entonces cuando yo entro a editar esa remision, al listar en el combo los canguros
  va a decir que yo tengo 8 disponibles, cuando en realidad serian 10 pues los 2 descontados son parte de esta remision, por lo tanto
  a la cantidad en base de datos (8) yo debo sumar los 2 que estoy usando en esta misma remision.
  Pasa lo mismo por ejemplo si en una remision alquilo la rana 2, al editar la rana 2 no me aparece en la lista porque en base de datos
  tiene cantidad disponible en cero.
  Acá en esta funcion yo recibo el id y retorno la cantidad de ese id usado en esta remision
  */
  getCantidadAdicional = (idProducto) => {
    ////console.log('Pregunta para',idProducto,'tiene',this.cantidadesEnEstaRemision);
    return this.cantidadesEnEstaRemision[idProducto]!==undefined?this.cantidadesEnEstaRemision[idProducto]:0;
  }

  render() {
    const { classes } = this.props;

    //console.log('Pintar selector prodctos con',this.state);
    if(this.state.conceptos.length===0) return null;

    //var total = this.state.data.reduce((prev,current) => prev+=isNaN(current.porcentaje)?0:current.porcentaje===""?0:parseInt(current.porcentaje),0);
    var total = getTotal(this.state.data);

    return (
      <React.Fragment>
        <div>
            {this.state.data.map((item,index) => {
              //console.log('Obtiene a ',item,index,this.state.conceptos);
              return <ItemTipoProducto
                key={item.id}
                items={this.state.conceptos}
                state={this.state}
                item={item}
                index={index}
                borrarItem={this.borrarItem}
                setItemData={this.setItemData}
                setItemIndividualData={this.setItemIndividualData}
                filtrarDatos={this.filtrarDatos}
                disabled={this.props.disabled}
                getCantidadAdicional={this.getCantidadAdicional}
              />
            })}      
          <Typography variant="h6" component="h6" className={classes.total}>Total porcentaje: <span style={{color:total===100?'green':'red'}}>{total}%</span></Typography>   
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddIcon />}
            disabled={this.props.disabled}
            onClick={() => {
              this.addItem();
            }}
          >
            Agregar concepto
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(styles,{ withTheme: true })(withContext(SelectorConceptos));
