import React, {Component} from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import logo from '../res/img/logo.png'; // with import


const styles = theme => ({ 

  mainCointainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    background: 'white',    
  },

  imgLogo: {
    objectFit: "contain",
    height: '10%',    
    minHeight: '70px',
  },  
});

class Splash extends Component {

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.mainCointainer}>    
        <img src={logo} className={classes.imgLogo} alt="logo" />            
      </div>      
    );
  }
}

Splash.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(Splash));
